/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CampaignBulkLookupPayload
 */
export interface CampaignBulkLookupPayload {
    /**
     * 
     * @type {Array<string>}
     * @memberof CampaignBulkLookupPayload
     */
    campaigns: Array<string>;
}

/**
 * Check if a given object implements the CampaignBulkLookupPayload interface.
 */
export function instanceOfCampaignBulkLookupPayload(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "campaigns" in value;

    return isInstance;
}

export function CampaignBulkLookupPayloadFromJSON(json: any): CampaignBulkLookupPayload {
    return CampaignBulkLookupPayloadFromJSONTyped(json, false);
}

export function CampaignBulkLookupPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): CampaignBulkLookupPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'campaigns': json['campaigns'],
    };
}

export function CampaignBulkLookupPayloadToJSON(value?: CampaignBulkLookupPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'campaigns': value.campaigns,
    };
}

