import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {Stack, useTheme} from "@mui/material";
import {Main} from "../layouts";

export const ContactUs = () => {
    const theme = useTheme();
    return (
        <Main>
            <Grid container spacing={3} paddingY={10} bgcolor={"secondary.main"}>
                <Grid container item md={6} bgcolor={"secondary.main"}>
                    <Stack>
                        <Typography color={"text.primary"} variant={"h3"} textAlign={"center"} gutterBottom>
                            Support
                        </Typography>
                        <Paper elevation={2} sx={{padding: 5, bgcolor: "secondary.main"}}>
                            <Typography color={"text.primary"} variant={"body1"} paragraph={true}>
                                BleacherFund stands out not only for its innovative fundraising capabilities but also
                                for its unwavering commitment to providing unparalleled support.
                                Recognizing the importance of accessibility, we offer a dedicated 24/7 support system
                                through both email and phone channels, ensuring that our users receive assistance
                                whenever they need it.
                                Our responsive and knowledgeable support team is ready to address inquiries promptly,
                                offering guidance and solutions to enhance the user experience.
                                Transparency is at the core of BleacherFund's ethos, and we extend this commitment to
                                our support services.
                                Users can easily navigate our website to access real-time updates on their fundraising
                                progress, track funds posted, and gauge their proximity to financial goals.
                                Furthermore, our website provides comprehensive contact information, empowering users to
                                seek additional assistance and clarification with utmost ease.
                                At BleacherFund, our stellar support is not just a service; it's a testament to our
                                dedication to user satisfaction and success in their fundraising endeavors.
                            </Typography>
                        </Paper>
                    </Stack>
                </Grid>
                <Grid container item md={6} bgcolor={"secondary.main"}>
                    <Stack>
                        <Typography color={"text.primary"} variant={"h3"} textAlign={"center"} gutterBottom>
                            Sales
                        </Typography>
                        <Paper elevation={2} sx={{padding: 5, flexGrow: 1, bgcolor: "secondary.main"}}>
                            <Typography color={"text.primary"} variant={"body1"} paragraph={true}>
                                Greetings from BleacherFund! Our competent Sales team is ready to elevate your
                                fundraising experience.
                                For personalized assistance and a seamless setup process, simply drop us an email
                                at <Link color={"primary.main"}
                                         href={"mailto:sales@bleacherfund.com"}>sales@bleacherfund.com</Link>.
                                Our dedicated sales staff is committed to prompt replies, ensuring that every inquiry is
                                met with the attention it deserves.
                                Whether you're a school, business, or organization, our team will guide you through each
                                step of setting up a fundraiser, leveraging our expertise to optimize your fundraising
                                potential.
                                At BleacherFund, we believe in empowering our clients, and our Sales team is here to
                                make your fundraising journey as smooth and successful as possible.
                                Reach out to us today, and let's embark on a journey to redefine the way you raise
                                funds!
                            </Typography>
                        </Paper>
                    </Stack>
                </Grid>
            </Grid>
        </Main>
    )
}