import {Card, Grid, Typography} from "@mui/material";
import React from "react";
import {USDollar} from "../utils/NumberFormatters";

interface DonationCardProps {
    // donation: DonationEntity,
    title: string
    donationSum: number
    variant: string
    rank?: number
}

export const DonationCard = (props: DonationCardProps) => {
    return (
        <Card variant="outlined"
              style={{
                  width: "100%",
                  display: "flex",
                  borderColor: "background.default",
                  justifyContent: "space-between",
                  height: 75
              }}>
            {props.rank &&
                <Grid container item xs={2}
                      sx={{
                          borderRight: 1,
                          borderColor: "background.default",
                          padding: 2,
                          alignItems: "center",
                          justifyContent: "center"
                      }}>
                    <Typography fontSize={"1.65rem"} color={"text.secondary"}>
                        {props.rank}
                    </Typography>
                </Grid>
            }
            <Grid container item xs={7} p={2} sx={{alignItems: "center"}}>
                <Typography fontSize={"1.3rem"} color={"text.secondary"}>
                    {props.title}
                </Typography>
            </Grid>
            <Grid container item xs={3} justifyContent={"flex-end"} p={2} sx={{alignItems: "center"}}>
                <Typography fontSize={"1.3rem"} color={"text.secondary"}>
                    {USDollar.format(props.donationSum)}
                </Typography>
            </Grid>
        </Card>
    )
}