import {CampaignListTable} from "../components/CampaignListTable";
import {useLocation} from "react-router-dom";
import {Main} from "../layouts";
import {Typography} from "@mui/material";
import Container from "components/Container";

export const CampaignSearch = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search).get("q")
    return (
        <Main>
            <Container>
                <Typography variant={"h3"} color={"secondary"}>Search Results</Typography>
                <CampaignListTable query={query}/>
            </Container>
        </Main>
    )
}