import {
    CampaignManagementApi,
    CampaignsApi,
    Configuration,
    DonationsApi,
    FetchParams,
    ResponseContext,
    TenantManagementApi, UpdatesApi,
    UserEndpointsApi
} from "../api";
import {Auth} from "aws-amplify";
import {Update} from "@mui/icons-material";

export const requestConfig = new Configuration({
    accessToken: async () => {
        return (await Auth.currentSession()).getIdToken().getJwtToken()
    },
    middleware: [{
        pre: preMiddleware
    }]
})

export function preMiddleware(context: ResponseContext): Promise<FetchParams | void> {
    return Promise.resolve({
        url: context.url,
        init: {
            ...context.init,
            mode: "cors"
        },
    });
}

const campaignApi = new CampaignsApi(requestConfig);
const campaignManagementApi = new CampaignManagementApi(requestConfig);
const userApi = new UserEndpointsApi(requestConfig);
const donationApi = new DonationsApi(requestConfig);
const tenantManagementApi = new TenantManagementApi(requestConfig);
const updatesApi = new UpdatesApi(requestConfig);
export {campaignApi, campaignManagementApi, userApi, donationApi, tenantManagementApi, updatesApi}