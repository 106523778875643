/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Campaign } from './Campaign';
import {
    CampaignFromJSON,
    CampaignFromJSONTyped,
    CampaignToJSON,
} from './Campaign';
import type { Tenant } from './Tenant';
import {
    TenantFromJSON,
    TenantFromJSONTyped,
    TenantToJSON,
} from './Tenant';

/**
 * 
 * @export
 * @interface Fundraiser
 */
export interface Fundraiser {
    /**
     * 
     * @type {Tenant}
     * @memberof Fundraiser
     */
    tenant: Tenant;
    /**
     * 
     * @type {Array<Campaign>}
     * @memberof Fundraiser
     */
    campaigns: Array<Campaign>;
}

/**
 * Check if a given object implements the Fundraiser interface.
 */
export function instanceOfFundraiser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "tenant" in value;
    isInstance = isInstance && "campaigns" in value;

    return isInstance;
}

export function FundraiserFromJSON(json: any): Fundraiser {
    return FundraiserFromJSONTyped(json, false);
}

export function FundraiserFromJSONTyped(json: any, ignoreDiscriminator: boolean): Fundraiser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tenant': TenantFromJSON(json['tenant']),
        'campaigns': ((json['campaigns'] as Array<any>).map(CampaignFromJSON)),
    };
}

export function FundraiserToJSON(value?: Fundraiser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tenant': TenantToJSON(value.tenant),
        'campaigns': ((value.campaigns as Array<any>).map(CampaignToJSON)),
    };
}

