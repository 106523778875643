import {useState} from "react"

import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import CloseIcon from "@mui/icons-material/Close"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import IconButton from "@mui/material/IconButton"

import {Campaign} from "../api"
import {campaignManagementApi} from "../config/RequestConfig"

interface CampaignStatusDialogProps {
    handleClose: () => void,
    open: boolean,
    fullScreen: boolean,
    campaign: Campaign,
}

export const CampaignStatusDialog = (props: CampaignStatusDialogProps) => {
    const {handleClose, open, campaign, fullScreen} = props
    const [submitting, setSubmitting] = useState(false)
    const action = campaign.status === "DRAFT" ? "Launch" : "End"

    async function updateCampaignStatus() {
        if (campaign.id === null) {
            return
        }
        setSubmitting(true)

        try {
            if (campaign.status === "DRAFT") {
                await campaignManagementApi.launchCampaign({campaignId: campaign.id})
            } else if (campaign.status === "ACTIVE") {
                await campaignManagementApi.endCampaign({campaignId: campaign.id})
            }
        } catch (error) {
            console.log("Error occurred while updating the campaign status", error)
        } finally {
            setSubmitting(false)
            handleClose()
        }
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="launch-dialog-title">
            <DialogTitle id="launch-dialog-title" color={"text.secondary"}>{action} Fundraiser</DialogTitle>
            <IconButton
                sx={{position: 'absolute', right: 8, top: 8, color: 'black'}}
                aria-label={"Close notify dialog"}
                onClick={handleClose}>
                <CloseIcon/>
            </IconButton>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to {action.toLocaleLowerCase()} this fundraiser? You cannot undo this action.
                </DialogContentText>
                <DialogActions>
                    <Button
                        sx={{color: "primary.main", backgroundColor: "background.default"}}
                        disabled={submitting}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant={"contained"}
                        sx={{color: "primary.main", backgroundColor: "background.default"}}
                        disabled={submitting}
                        color={"primary"}
                        startIcon={submitting ? <CircularProgress size={"1rem"}/> : undefined}
                        onClick={updateCampaignStatus}
                    >
                        {action}
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}
