/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TenantCreatePayload
 */
export interface TenantCreatePayload {
    /**
     * 
     * @type {string}
     * @memberof TenantCreatePayload
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TenantCreatePayload
     */
    addressLineOne: string;
    /**
     * 
     * @type {string}
     * @memberof TenantCreatePayload
     */
    addressLineTwo?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantCreatePayload
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof TenantCreatePayload
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof TenantCreatePayload
     */
    zipcode: string;
    /**
     * 
     * @type {string}
     * @memberof TenantCreatePayload
     */
    primaryUser: string;
}

/**
 * Check if a given object implements the TenantCreatePayload interface.
 */
export function instanceOfTenantCreatePayload(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "addressLineOne" in value;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "zipcode" in value;
    isInstance = isInstance && "primaryUser" in value;

    return isInstance;
}

export function TenantCreatePayloadFromJSON(json: any): TenantCreatePayload {
    return TenantCreatePayloadFromJSONTyped(json, false);
}

export function TenantCreatePayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): TenantCreatePayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'addressLineOne': json['address_line_one'],
        'addressLineTwo': !exists(json, 'address_line_two') ? undefined : json['address_line_two'],
        'city': json['city'],
        'state': json['state'],
        'zipcode': json['zipcode'],
        'primaryUser': json['primary_user'],
    };
}

export function TenantCreatePayloadToJSON(value?: TenantCreatePayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'address_line_one': value.addressLineOne,
        'address_line_two': value.addressLineTwo,
        'city': value.city,
        'state': value.state,
        'zipcode': value.zipcode,
        'primary_user': value.primaryUser,
    };
}

