/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Campaign } from './Campaign';
import {
    CampaignFromJSON,
    CampaignFromJSONTyped,
    CampaignToJSON,
} from './Campaign';

/**
 * 
 * @export
 * @interface FeaturedCampaignsResponse
 */
export interface FeaturedCampaignsResponse {
    /**
     * 
     * @type {Array<Campaign>}
     * @memberof FeaturedCampaignsResponse
     */
    recentCampaigns: Array<Campaign>;
    /**
     * 
     * @type {Array<Campaign>}
     * @memberof FeaturedCampaignsResponse
     */
    nearestToGoal: Array<Campaign>;
    /**
     * 
     * @type {Array<Campaign>}
     * @memberof FeaturedCampaignsResponse
     */
    nearestToEnd: Array<Campaign>;
}

/**
 * Check if a given object implements the FeaturedCampaignsResponse interface.
 */
export function instanceOfFeaturedCampaignsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "recentCampaigns" in value;
    isInstance = isInstance && "nearestToGoal" in value;
    isInstance = isInstance && "nearestToEnd" in value;

    return isInstance;
}

export function FeaturedCampaignsResponseFromJSON(json: any): FeaturedCampaignsResponse {
    return FeaturedCampaignsResponseFromJSONTyped(json, false);
}

export function FeaturedCampaignsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeaturedCampaignsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recentCampaigns': ((json['recent_campaigns'] as Array<any>).map(CampaignFromJSON)),
        'nearestToGoal': ((json['nearest_to_goal'] as Array<any>).map(CampaignFromJSON)),
        'nearestToEnd': ((json['nearest_to_end'] as Array<any>).map(CampaignFromJSON)),
    };
}

export function FeaturedCampaignsResponseToJSON(value?: FeaturedCampaignsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recent_campaigns': ((value.recentCampaigns as Array<any>).map(CampaignToJSON)),
        'nearest_to_goal': ((value.nearestToGoal as Array<any>).map(CampaignToJSON)),
        'nearest_to_end': ((value.nearestToEnd as Array<any>).map(CampaignToJSON)),
    };
}

