import * as Yup from 'yup';

const MAX_FILE_SIZE = 102400; //100KB
const validImageFileExtensions = ['image/jpg', 'image/gif', 'image/png', 'image/jpeg', 'image/svg', 'image/webp'];

const checkFileType = (file: Blob | any) => {
    let valid = true
    if (file && validImageFileExtensions.indexOf(file.type) === -1) {
        valid = false
    }
    return valid
}

const checkFileSize = (file: Blob | any) => {
    let valid = true
    if(file && file.size > MAX_FILE_SIZE) {
        valid = false
    }
    return valid
}

const bannedWordsFilter = (value: string | undefined) => {
    if (!value) {
        return true
    }

    const bannedWords = ['test', 'dumb'] //TODO fill in all the words you don't want, lowercase
    const isBanned = bannedWords.some((word) => value.toLowerCase().includes(word))
    return isBanned ? 'Profanity is not allowed' : true;
}

export const CampaignCreationSchema = Yup.object().shape({
    tenantId: Yup.string()
    .required('Required'),
    name: Yup.string()
    .min(2, 'Please submit a longer campaign name.')
    .max(40, 'Please submit a shorter campaign name.')
    // .test(
    //     "name",
    //     "Cannot include the word 'fundraiser' in fundraiser name",
    //     (value) => {value?.toLowerCase().includes("fundraiser")}
    // )
    // .test("name", "Profanity is not allowed", bannedWordsFilter)
    .required('Fundraiser Name is required'),
    teamName: Yup.string()
    .min(2, 'Please submit a longer team name.')
    .max(50, 'Please submit a shorter team name.')
    .required('Required'),
    activity: Yup.string()
    .min(2, 'Please submit a longer activity name.')
    .max(25, 'Please submit a shorter activity name.')
    .required('Required'),
    mission: Yup.string()
    .min(2, 'Please submit a longer mission statement.')
    .max(240, 'Please submit a shorter mission statement.')
    .required('Required'),
    donationGoal: Yup.number()
    .min(1, 'Donation goal must be greater than $1')
    .required('Required'),
    targetEndDate: Yup.date()
    .min(new Date(), "Fundraiser end date must be in the future")
    .required('Required'),
    code: Yup.string()
    .required('Required'),
    groups: Yup.array()
    .of(
        Yup.object().shape({
            name: Yup.string()
            .min(2, 'Please submit a longer group name.')
            .max(25, 'Please submit a shorter group name.')
            .required('Group name is required'),
        })
    )
    .min(1, 'Must have at least 1 group')
    .max(5, 'Must have not more than 5 groups')
    .required('Required'),
    teamLogo: Yup.array()
        .of(
            Yup.mixed()
            .test("is-valid-type", "Not a valid image type", checkFileType)
            .test("is-valid-size", `Max size is: 100KB`,checkFileSize),
        )
    .max(1, "Only one team logo is allowed"),
    photoGallery: Yup.array()
    .of(
        Yup.mixed()
        .test("is-valid-type", "Not a valid image type", checkFileType)
        .test("is-valid-size", `Max size is: 100KB`,checkFileSize),
    )
    .max(5, 'Must have no more than 5 images in photo gallery'),
});
