import React from 'react';
import './App.css';
import {Route, Routes} from 'react-router-dom'
import {ContactUs} from "./pages/ContactUs";
import {SignIn} from "./pages/SignIn";
import {CampaignSearch} from "./pages/CampaignSearch";
import Box from "@mui/material/Box";
import {Authenticator} from '@aws-amplify/ui-react';
import {MyCampaigns} from "./pages/MyCampaigns";
import {Privacy} from "./pages/Privacy";
import {CampaignCreation} from './pages/CampaignCreation';
import About from "./views/About";
import Home from "./views/Home";
import Page from "./components/Page";
import Fundraiser from "./views/Fundraiser";

function App() {
    return (
        <>
            <Authenticator.Provider>
                <Page>
                    <Box sx={{display: "flex", minHeight: "100vh", flexDirection: "column"}}>
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/fundraiser/:id" element={<Fundraiser/>}/>
                            <Route path="/contact" element={<ContactUs/>}/>
                            <Route path="/about" element={<About/>}/>
                            <Route path="/login" element={<SignIn/>}/>
                            <Route path="/search" element={<CampaignSearch/>}/>
                            <Route path="/my-fundraisers" element={<MyCampaigns/>}/>
                            <Route path="/privacy" element={<Privacy/>}/>
                            <Route path="/fundraiser/new" element={<CampaignCreation/>}/>
                        </Routes>
                    </Box>
                </Page>
            </Authenticator.Provider>
        </>
    );
}

export default App;
