/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GroupCreatePayload } from './GroupCreatePayload';
import {
    GroupCreatePayloadFromJSON,
    GroupCreatePayloadFromJSONTyped,
    GroupCreatePayloadToJSON,
} from './GroupCreatePayload';

/**
 * 
 * @export
 * @interface CampaignCreatePayload
 */
export interface CampaignCreatePayload {
    /**
     * 
     * @type {string}
     * @memberof CampaignCreatePayload
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignCreatePayload
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignCreatePayload
     */
    teamName: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignCreatePayload
     */
    activity: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignCreatePayload
     */
    mission: string;
    /**
     * 
     * @type {number}
     * @memberof CampaignCreatePayload
     */
    donationGoal: number;
    /**
     * 
     * @type {Date}
     * @memberof CampaignCreatePayload
     */
    targetEndDate: Date;
    /**
     * 
     * @type {string}
     * @memberof CampaignCreatePayload
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignCreatePayload
     */
    youtubeLink?: string;
    /**
     * 
     * @type {Array<GroupCreatePayload>}
     * @memberof CampaignCreatePayload
     */
    groups: Array<GroupCreatePayload>;
}

/**
 * Check if a given object implements the CampaignCreatePayload interface.
 */
export function instanceOfCampaignCreatePayload(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "tenantId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "teamName" in value;
    isInstance = isInstance && "activity" in value;
    isInstance = isInstance && "mission" in value;
    isInstance = isInstance && "donationGoal" in value;
    isInstance = isInstance && "targetEndDate" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "groups" in value;

    return isInstance;
}

export function CampaignCreatePayloadFromJSON(json: any): CampaignCreatePayload {
    return CampaignCreatePayloadFromJSONTyped(json, false);
}

export function CampaignCreatePayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): CampaignCreatePayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tenantId': json['tenant_id'],
        'name': json['name'],
        'teamName': json['team_name'],
        'activity': json['activity'],
        'mission': json['mission'],
        'donationGoal': json['donation_goal'],
        'targetEndDate': (new Date(json['target_end_date'])),
        'code': json['code'],
        'youtubeLink': !exists(json, 'youtube_link') ? undefined : json['youtube_link'],
        'groups': ((json['groups'] as Array<any>).map(GroupCreatePayloadFromJSON)),
    };
}

export function CampaignCreatePayloadToJSON(value?: CampaignCreatePayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tenant_id': value.tenantId,
        'name': value.name,
        'team_name': value.teamName,
        'activity': value.activity,
        'mission': value.mission,
        'donation_goal': value.donationGoal,
        'target_end_date': (formatTargetEndDate(value.targetEndDate)),
        'code': value.code,
        'youtube_link': value.youtubeLink,
        'groups': ((value.groups as Array<any>).map(GroupCreatePayloadToJSON)),
    };
}

function formatTargetEndDate(date: Date): string {
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    const year = date.getFullYear()
    return `${month}-${day}-${year}`
}
