import React from 'react';
import Box from '@mui/material/Box';

import Main from 'layouts/Main';
import Container from 'components/Container';

import {Hero,} from './components';
import FeaturedFundraisers from "./components/FeaturedFundraisers";

const Home = (): JSX.Element => {
    return (
        <Main colorInvert={true}>
            <Hero/>
            <Box bgcolor={'alternate.main'}>
                <Container>
                    <FeaturedFundraisers/>
                </Container>
            </Box>
        </Main>
    );
};

export default Home;
