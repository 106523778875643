import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useTheme} from '@mui/material/styles';
import {Container, Divider, Stack} from "@mui/material";
import {Link} from "react-router-dom";

const Footer = (): JSX.Element => {
    const theme = useTheme();
    const {mode} = theme.palette;
    const linkList = [
        {
            name: "Home",
            link: "/"
        },
        {
            name: "Contact Us",
            link: "/contact"
        },
        {
            name: "About Us",
            link: "/about"
        },
        {
            name: "Privacy Policy",
            link: "/privacy"
        },
    ]
    return (
        <Container maxWidth="lg">
            <Grid container alignItems="center">
                <Grid item xs={2}>
                    <img src={"/logo.png"} alt={"logo"} height={"100%"} width={"100%"}/>
                </Grid>
                <Grid container item xs={10} direction={"column"} alignContent={"center"}>
                    <Stack direction="row" divider={<Divider orientation="vertical" flexItem/>}
                           spacing={{xs: 0.5, sm: 2, md: 2}}>
                        {linkList.map((item, index) => (
                            <Link
                                key={index}
                                to={item.link}
                                color="white"
                                style={{
                                    textDecoration: 'none',
                                    display: "flex",
                                    alignItems: "center",
                                    textAlign: "center"
                                }}
                            >
                                <Typography color="white" variant="subtitle1">
                                    {item.name}
                                </Typography>
                            </Link>
                        ))}
                    </Stack>
                    <Grid item textAlign={"center"}>
                        <Typography color="white" variant="subtitle1">
                            {`© ${new Date().getFullYear()} | Bleacherfund, LLC.`}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Footer;
