import React from 'react';
import Divider from '@mui/material/Divider';
import Main from 'layouts/Main';
import Container from 'components/Container';
import {Hero} from './components';
import Team from "./components/Team";

const About = (): JSX.Element => {
    return (
        <Main colorInvert={true}>
            <Hero/>
            <Container maxWidth={800} paddingY={'0 !important'}>
                <Divider/>
            </Container>
            <Container>
                <Team/>
            </Container>
        </Main>
    );
};

export default About;
