/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotifyPayload
 */
export interface NotifyPayload {
    /**
     * 
     * @type {string}
     * @memberof NotifyPayload
     */
    message: string;
    /**
     *
     * @type {string}
     * @memberof NotifyPayload
     */
    status: NotifyPayloadStatusEnum;
}


/**
 * @export
 */
export const NotifyPayloadStatusEnum = {
    Public: 'PUBLIC',
    Private: 'PRIVATE'
} as const;
export type NotifyPayloadStatusEnum = typeof NotifyPayloadStatusEnum[keyof typeof NotifyPayloadStatusEnum];


/**
 * Check if a given object implements the NotifyPayload interface.
 */
export function instanceOfNotifyPayload(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "message" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function NotifyPayloadFromJSON(json: any): NotifyPayload {
    return NotifyPayloadFromJSONTyped(json, false);
}

export function NotifyPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotifyPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': json['message'],
        'status': json['status'],
    };
}

export function NotifyPayloadToJSON(value?: NotifyPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': value.message,
        'status': value.status,
    };
}

