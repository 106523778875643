/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CampaignListResponse,
  MyFundraisersResponse,
  UserTenantResponse,
} from '../models/index';
import {
    CampaignListResponseFromJSON,
    CampaignListResponseToJSON,
    MyFundraisersResponseFromJSON,
    MyFundraisersResponseToJSON,
    UserTenantResponseFromJSON,
    UserTenantResponseToJSON,
} from '../models/index';

/**
 * 
 */
export class UserEndpointsApi extends runtime.BaseAPI {

    /**
     */
    async myCampaignsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CampaignListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/manage/user/campaigns`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CampaignListResponseFromJSON(jsonValue));
    }

    /**
     */
    async myCampaigns(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CampaignListResponse> {
        const response = await this.myCampaignsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async myFundraisersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MyFundraisersResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/manage/user/my-fundraisers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MyFundraisersResponseFromJSON(jsonValue));
    }

    /**
     */
    async myFundraisers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MyFundraisersResponse> {
        const response = await this.myFundraisersRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async myTenantsFromJWTRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserTenantResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/manage/user/tenants`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserTenantResponseFromJSON(jsonValue));
    }

    /**
     */
    async myTenantsFromJWT(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserTenantResponse> {
        const response = await this.myTenantsFromJWTRaw(initOverrides);
        return await response.value();
    }

}
