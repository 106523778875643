/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Campaign,
  CampaignBulkLookupPayload,
  CampaignBulkLookupResponse,
  CampaignListResponse,
  CampaignSearchPayload,
  FeaturedCampaignsResponse,
  ManifestItem,
} from '../models/index';
import {
    CampaignFromJSON,
    CampaignToJSON,
    CampaignBulkLookupPayloadFromJSON,
    CampaignBulkLookupPayloadToJSON,
    CampaignBulkLookupResponseFromJSON,
    CampaignBulkLookupResponseToJSON,
    CampaignListResponseFromJSON,
    CampaignListResponseToJSON,
    CampaignSearchPayloadFromJSON,
    CampaignSearchPayloadToJSON,
    FeaturedCampaignsResponseFromJSON,
    FeaturedCampaignsResponseToJSON,
    ManifestItemFromJSON,
    ManifestItemToJSON,
} from '../models/index';

export interface BulkCampaignLookupRequest {
    campaignBulkLookupPayload: CampaignBulkLookupPayload;
}

export interface GetCampaignRequest {
    id: string;
}

export interface GetCampaignFileManifestRequest {
    campaignId: string;
}

export interface ListCampaignsRequest {
    size?: number;
    page?: number;
    status?: ListCampaignsStatusEnum;
}

export interface SearchCampaignsRequest {
    campaignSearchPayload: CampaignSearchPayload;
}

export interface VerifyCampaignRequest {
    code: string;
}

/**
 * 
 */
export class CampaignsApi extends runtime.BaseAPI {

    /**
     */
    async bulkCampaignLookupRaw(requestParameters: BulkCampaignLookupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CampaignBulkLookupResponse>> {
        if (requestParameters.campaignBulkLookupPayload === null || requestParameters.campaignBulkLookupPayload === undefined) {
            throw new runtime.RequiredError('campaignBulkLookupPayload','Required parameter requestParameters.campaignBulkLookupPayload was null or undefined when calling bulkCampaignLookup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/campaign/bulk`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CampaignBulkLookupPayloadToJSON(requestParameters.campaignBulkLookupPayload),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CampaignBulkLookupResponseFromJSON(jsonValue));
    }

    /**
     */
    async bulkCampaignLookup(requestParameters: BulkCampaignLookupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CampaignBulkLookupResponse> {
        const response = await this.bulkCampaignLookupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCampaignRaw(requestParameters: GetCampaignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Campaign>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCampaign.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/campaign/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CampaignFromJSON(jsonValue));
    }

    /**
     */
    async getCampaign(requestParameters: GetCampaignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Campaign> {
        const response = await this.getCampaignRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCampaignFileManifestRaw(requestParameters: GetCampaignFileManifestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ManifestItem>>> {
        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError('campaignId','Required parameter requestParameters.campaignId was null or undefined when calling getCampaignFileManifest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/campaign/{campaignId}/file-manifest`.replace(`{${"campaignId"}}`, encodeURIComponent(String(requestParameters.campaignId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ManifestItemFromJSON));
    }

    /**
     */
    async getCampaignFileManifest(requestParameters: GetCampaignFileManifestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ManifestItem>> {
        const response = await this.getCampaignFileManifestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getFeaturedCampaignsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FeaturedCampaignsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/campaign/featured`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeaturedCampaignsResponseFromJSON(jsonValue));
    }

    /**
     */
    async getFeaturedCampaigns(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FeaturedCampaignsResponse> {
        const response = await this.getFeaturedCampaignsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async listCampaignsRaw(requestParameters: ListCampaignsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Campaign>>> {
        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/campaign`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CampaignFromJSON));
    }

    /**
     */
    async listCampaigns(requestParameters: ListCampaignsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Campaign>> {
        const response = await this.listCampaignsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchCampaignsRaw(requestParameters: SearchCampaignsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CampaignListResponse>> {
        if (requestParameters.campaignSearchPayload === null || requestParameters.campaignSearchPayload === undefined) {
            throw new runtime.RequiredError('campaignSearchPayload','Required parameter requestParameters.campaignSearchPayload was null or undefined when calling searchCampaigns.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/campaign/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CampaignSearchPayloadToJSON(requestParameters.campaignSearchPayload),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CampaignListResponseFromJSON(jsonValue));
    }

    /**
     */
    async searchCampaigns(requestParameters: SearchCampaignsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CampaignListResponse> {
        const response = await this.searchCampaignsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async verifyCampaignRaw(requestParameters: VerifyCampaignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Campaign>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling verifyCampaign.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/campaign/verify/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CampaignFromJSON(jsonValue));
    }

    /**
     */
    async verifyCampaign(requestParameters: VerifyCampaignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Campaign> {
        const response = await this.verifyCampaignRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const ListCampaignsStatusEnum = {
    Draft: 'DRAFT',
    Active: 'ACTIVE',
    Completed: 'COMPLETED'
} as const;
export type ListCampaignsStatusEnum = typeof ListCampaignsStatusEnum[keyof typeof ListCampaignsStatusEnum];
