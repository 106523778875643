import React from "react";
import {Grid, Tooltip, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {USDollar} from "../utils/NumberFormatters";
import {FacebookMessengerShare, FacebookShare, TwitterShare} from "react-share-kit";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ShareIcon from '@mui/icons-material/Share';

interface DonateAndShareProps {
    donationTotal: number
    donationGoal: number
    paymentLink: string | undefined
    campaignId: string
}

export const DonateAndShare = (props: DonateAndShareProps) => {
    const shareUrl = `https://www.bleacherfund.com/fundraiser/${props.campaignId}`;
    const [copied, setCopied] = React.useState(false);
    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(shareUrl);
            setCopied(true);
            setTimeout(() => setCopied(false), 3000);
        } catch (error) {
            console.log("Error copying to clipboard: ", error)
        }
    };

    return (
        <Grid
            container
            spacing={2}
            justifyContent={"center"}
            padding={2}
            bgcolor={"secondary.main"}
            borderRadius={5}
        >
            <Grid
                item
                xs={12}
                marginBottom={1}
                sx={{textAlign: "center"}}
            >
                <Typography variant={"h4"}>
                    {USDollar.format(props.donationTotal)} of {USDollar.format(props.donationGoal)} raised
                </Typography>
            </Grid>
            <Grid xs={12} item>
                <Button
                    variant={"contained"}
                    fullWidth
                    onClick={() => window.open(props.paymentLink, "_blank")}
                >
                    <Typography variant={"h5"}>Donate</Typography>
                </Button>
            </Grid>
            <Grid
                xs={12}
                container
                flexDirection={"row"}
                justifyContent={"space-evenly"}
                spacing={1}
                marginTop={3}
                alignItems={"center"}
            >
                <FacebookShare size={40} url={shareUrl} quote={shareUrl}/>
                <TwitterShare
                    size={40}
                    url={shareUrl}
                    hashtags={["#bleacherfund", "#fundraiser"]}
                />
                <FacebookMessengerShare
                    size={40}
                    url={shareUrl}
                    redirectUri={shareUrl}
                    appId={'523248043214523'}
                />
                <>
                    {copied ? (
                        <Tooltip
                            title={"Copied Fundraiser Link to Clipboard"}
                            color={"primary"}
                        >
                            <CheckCircleIcon style={{fontSize: 40}}/>
                        </Tooltip>
                    ) : <ShareIcon style={{fontSize: 40}} onClick={copyToClipboard}/>}
                </>

            </Grid>
        </Grid>
    )
}