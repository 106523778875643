/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';

/**
 *
 * @export
 * @interface Update
 */
export interface Update {
    /**
     *
     * @type {Date}
     * @memberof Update
     */
    createdAt: Date;
    /**
     *
     * @type {string}
     * @memberof Update
     */
    message: string;
}

/**
 * Check if a given object implements the Update interface.
 */
export function instanceOfUpdate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "message" in value;

    return isInstance;
}

export function UpdateFromJSON(json: any): Update {
    return UpdateFromJSONTyped(json, false);
}

export function UpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): Update {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'createdAt': (new Date(json['created_at'])),
        'message': json['message'],
    };
}

export function UpdateToJSON(value?: Update | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'created_at': (value.createdAt.toISOString()),
        'message': value.message,
    };
}

