const pages = {
    landings: [
        {
            title: 'Mobile App',
            href: '/mobile-app',
        },
        {
            title: 'Desktop App',
            href: '/desktop-app',
        },
        {
            title: 'Startup',
            href: '/startup',
        },
        {
            title: 'Expo',
            href: '/expo',
        },
        {
            title: 'Marketing',
            href: '/',
        },
        {
            title: 'Service',
            href: '/service',
        },
        {
            title: 'Enterprise',
            href: '/enterprise',
        },
        {
            title: 'Cloud Hosting',
            href: '/cloud-hosting',
        },
        {
            title: 'Design Company',
            href: '/design-company',
        },
        {
            title: 'Web Basic',
            href: '/web-basic',
        },
        {
            title: 'Overview',
            href: '/home',
        },
        {
            title: 'Agency',
            href: '/agency',
        },
        {
            title: 'E-Learning',
            href: '/e-learning',
        },
        {
            title: 'Coworking',
            href: '/coworking',
        },
        {
            title: 'Rental',
            href: '/rental',
        },
        {
            title: 'Job Listing',
            href: '/job-listing',
        },
        {
            title: 'Logistics',
            href: '/logistics',
        },
        {
            title: 'E-commerce',
            href: '/e-commerce',
        },
    ],
    company: [
        {
            title: 'About Us',
            href: '/about',
        },
        {
            title: 'Contact Us',
            href: '/contact',
        },
        {
            title: 'Privacy Policy',
            href: '/privacy',
        }
    ],
    account: [
        {
            title: 'My Fundraisers',
            href: '/my-fundraisers',
        },
    ],
    secondary: [
        {
            title: 'Help Center: Overview',
            href: '/help-center',
        },
        {
            title: 'Help Center: Article',
            href: '/help-center-article',
        },
        {
            title: 'FAQ',
            href: '/faq',
        },
        {
            title: 'Error 404: Simple',
            href: '/not-found',
        },
        {
            title: 'Error 404: Cover',
            href: '/not-found-cover',
        },
        {
            title: 'Career Page Minimal',
            href: '/career-listing-minimal',
        },
    ],
};

export default pages;
