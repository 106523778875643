/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
    UpdateResponse,
} from '../models/index';
import {
    UpdateResponseFromJSON,
    UpdateResponseToJSON,
} from '../models/index';

export interface ListPublicCampaignUpdatesRequest {
    campaignId: string;
}

/**
 *
 */
export class UpdatesApi extends runtime.BaseAPI {

    /**
     */
    async listPublicCampaignUpdatesRaw(requestParameters: ListPublicCampaignUpdatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateResponse>> {
        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError('campaignId', 'Required parameter requestParameters.campaignId was null or undefined when calling listPublicCampaignUpdates.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/updates/{campaignId}`.replace(`{${"campaignId"}}`, encodeURIComponent(String(requestParameters.campaignId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateResponseFromJSON(jsonValue));
    }

    /**
     */
    async listPublicCampaignUpdates(requestParameters: ListPublicCampaignUpdatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateResponse> {
        const response = await this.listPublicCampaignUpdatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
