/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Tenant
 */
export interface Tenant {
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof Tenant
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Tenant
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    status: TenantStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    addressLineOne: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    addressLineTwo?: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    zipcode: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    primaryUser: string;
}


/**
 * @export
 */
export const TenantStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;
export type TenantStatusEnum = typeof TenantStatusEnum[keyof typeof TenantStatusEnum];


/**
 * Check if a given object implements the Tenant interface.
 */
export function instanceOfTenant(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "addressLineOne" in value;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "zipcode" in value;
    isInstance = isInstance && "primaryUser" in value;

    return isInstance;
}

export function TenantFromJSON(json: any): Tenant {
    return TenantFromJSONTyped(json, false);
}

export function TenantFromJSONTyped(json: any, ignoreDiscriminator: boolean): Tenant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'name': json['name'],
        'status': json['status'],
        'addressLineOne': json['address_line_one'],
        'addressLineTwo': !exists(json, 'address_line_two') ? undefined : json['address_line_two'],
        'city': json['city'],
        'state': json['state'],
        'zipcode': json['zipcode'],
        'primaryUser': json['primary_user'],
    };
}

export function TenantToJSON(value?: Tenant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
        'name': value.name,
        'status': value.status,
        'address_line_one': value.addressLineOne,
        'address_line_two': value.addressLineTwo,
        'city': value.city,
        'state': value.state,
        'zipcode': value.zipcode,
        'primary_user': value.primaryUser,
    };
}

