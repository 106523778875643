import React, {useEffect, useState} from 'react';
import {alpha, useTheme} from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

interface Props {
    title: string;
    items: Array<PageItem>;
}

const NavItem = ({title, items}: Props): JSX.Element => {
    const theme = useTheme();
    const [activeLink, setActiveLink] = useState('');
    useEffect(() => {
        setActiveLink(window && window.location ? window.location.pathname : '');
    }, []);

    const hasActiveLink = () => items.find((i) => i.href === activeLink);

    return (
        <Box>
            <Accordion
                disableGutters
                elevation={0}
                sx={{backgroundColor: 'transparent'}}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon color={'secondary'}/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{padding: 0}}
                >
                    <Typography
                        fontWeight={hasActiveLink() ? 600 : 400}
                        color={'text.secondary'}
                    >
                        {title}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{padding: 0}}>
                    <Grid container spacing={1}>
                        {items.map((p, i) => (
                            <Grid item key={i} xs={12}>
                                <Button
                                    size={'large'}
                                    component={'a'}
                                    href={p.href}
                                    fullWidth
                                    sx={{
                                        justifyContent: 'flex-start',
                                        color: theme.palette.text.secondary,
                                        backgroundColor:
                                            activeLink === p.href
                                                ? alpha(theme.palette.primary.main, 0.1)
                                                : 'transparent',
                                        fontWeight: activeLink === p.href ? 600 : 400,
                                    }}
                                >
                                    {p.title}
                                    {p.isNew && (
                                        <Box
                                            padding={0.5}
                                            display={'inline-flex'}
                                            borderRadius={1}
                                            bgcolor={'primary.main'}
                                            marginLeft={2}
                                        >
                                            <Typography
                                                variant={'caption'}
                                                sx={{color: 'common.white', lineHeight: 1}}
                                            >
                                                new
                                            </Typography>
                                        </Box>
                                    )}
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default NavItem;
