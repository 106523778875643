/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import type {Update} from './Update';
import {
    UpdateFromJSON,
    UpdateFromJSONTyped,
    UpdateToJSON,
} from './Update';

/**
 *
 * @export
 * @interface UpdateResponse
 */
export interface UpdateResponse {
    /**
     *
     * @type {Array<Update>}
     * @memberof UpdateResponse
     */
    updates: Array<Update>;
}

/**
 * Check if a given object implements the UpdateResponse interface.
 */
export function instanceOfUpdateResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "updates" in value;

    return isInstance;
}

export function UpdateResponseFromJSON(json: any): UpdateResponse {
    return UpdateResponseFromJSONTyped(json, false);
}

export function UpdateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'updates': ((json['updates'] as Array<any>).map(UpdateFromJSON)),
    };
}

export function UpdateResponseToJSON(value?: UpdateResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'updates': ((value.updates as Array<any>).map(UpdateToJSON)),
    };
}

