import {Grid, Typography} from "@mui/material";
import React, {useEffect} from "react";
import {Campaign, Update, UpdateResponse} from "../api";
import {updatesApi} from "../config/RequestConfig";
import {UpdateCard} from "./UpdateCard";

interface CampaignUpdateListProps {
    campaign: Campaign
}

export const CampaignUpdateList = (props: CampaignUpdateListProps) => {
    const [updates, setUpdates] = React.useState<Update[]>([]);

    useEffect(() => {
        updatesApi.listPublicCampaignUpdates({campaignId: props.campaign.id})
            .then((resp: UpdateResponse) => {
                    setUpdates(resp.updates);
                }
            );
    }, [props.campaign.id]);

    return (
        <Grid
            item
            xs={12}
            md={12}
            bgcolor={"primary.main"}
            border={1}
            // margin={2}
            padding={2}
            borderRadius={2.5}
            justifyContent={"center"}
            textAlign={"center"}
        >
            {updates.length === 0 ?
                <Typography variant={"h5"} color={"text.secondary"}>No updates yet</Typography>
                :
                updates?.sort((a: Update, b: Update) => {
                    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
                }).slice(0, 3).map((update, index) => (
                    <UpdateCard update={update}/>
                ))}
        </Grid>

    )
}