import {Divider, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";

import React, {useEffect, useState} from "react";
import {donationApi} from "../../../../config/RequestConfig";
import {Campaign, IndividualLeaderboardItem} from "../../../../api";
import {DonationCard} from "../../../../components/DonationCard";
import TitleFormatter from "../../../../utils/StringFormatters";
import Box from "@mui/material/Box";

interface GroupLeaderboardProps {
    campaign: Campaign
}

const GroupLeaderboard = (p: GroupLeaderboardProps) => {
    const [groupLeaderboard, setGroupLeaderboard] = useState<IndividualLeaderboardItem[]>();

    useEffect(() => {
        donationApi.getGroupLeaderboard({campaignId: p.campaign.id})
            .then((groups) => {
                setGroupLeaderboard(groups);
            })
            .catch((err) => console.log("Error pulling group leaderboard", err));
    }, [p.campaign.id]);
    return (
        <Grid
            item
            xs={12}
            sm={12}
            md={5}
            bgcolor={"secondary.main"}
            borderRadius={5}
            padding={2}
            margin={1}
        >
            <Divider>
                <Typography
                    variant={"h4"}
                    paddingY={2}
                    color={"text.main"}
                >
                    Leaderboard
                </Typography>
            </Divider>
            {(groupLeaderboard === undefined || groupLeaderboard.length === 0) ? (
                <Typography
                    fontSize={"1.65rem"}
                    align="center"
                    color={"text.primary"}
                >
                    No Donations Yet
                </Typography>
            ) : (
                <Grid
                    item
                    spacing={2}
                >
                    {groupLeaderboard.map((group, index) => (
                        <Box margin={2}>
                            <DonationCard
                                title={TitleFormatter(group.group)}
                                donationSum={group.donationSum}
                                variant={"top"}
                                rank={index + 1}
                            />
                        </Box>
                    ))}
                </Grid>)
            }
            {/*<Stack>*/}
            {/*    <Card variant="outlined" sx={{height: 200, bgcolor: "white"}}>*/}
            {/*        <CardContent>*/}
            {/*            {(groupLeaderboard === undefined || groupLeaderboard.length === 0) ? (*/}
            {/*                <Typography*/}
            {/*                    fontSize={"1.65rem"}*/}
            {/*                    align="center"*/}
            {/*                    color={"text.secondary"}*/}
            {/*                >*/}
            {/*                    No Donations Yet*/}
            {/*                </Typography>*/}
            {/*            ) : (*/}
            {/*                <Grid item sx={{color: "white"}}>*/}
            {/*                    {groupLeaderboard.map((group, index) => (*/}
            {/*                        <DonationCard*/}
            {/*                            title={TitleFormatter(group.group)}*/}
            {/*                            donationSum={group.donationSum}*/}
            {/*                            variant={"top"}*/}
            {/*                            rank={index + 1}*/}
            {/*                        />*/}
            {/*                    ))}*/}
            {/*                </Grid>)*/}
            {/*            }*/}
            {/*        </CardContent>*/}
            {/*    </Card>*/}
            {/*</Stack>*/}
        </Grid>
    )
}

export default GroupLeaderboard;