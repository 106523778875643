import React from 'react';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import {SidebarNav} from './components';
import {useAuthenticator} from "@aws-amplify/ui-react";
import Button from "@mui/material/Button";
import {Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useTheme} from "@mui/material/styles";

interface Props {
    // eslint-disable-next-line @typescript-eslint/ban-types
    onClose: () => void;
    open: boolean;
    variant: 'permanent' | 'persistent' | 'temporary' | undefined;
    pages: {
        account: Array<PageItem>,
        company: Array<PageItem>;
    };
}

const Sidebar = ({pages, open, variant, onClose}: Props): JSX.Element => {
    const {user, signOut} = useAuthenticator(context => [context.user]);
    const {authStatus} = useAuthenticator(context => [context.authStatus]);
    const navigate = useNavigate();
    const theme = useTheme();
    return (
        <Drawer
            anchor="right"
            onClose={() => onClose()}
            open={open}
            variant={variant}
            sx={{
                '& .MuiPaper-root': {
                    width: '100%',
                    maxWidth: 280,
                },
            }}
        >
            <Box
                sx={{
                    height: '100%',
                    padding: 1,
                }}
            >
                <SidebarNav pages={pages}/>
                {authStatus === 'authenticated' ? (
                    <Button
                        onClick={() => signOut()}
                        sx={{
                            justifyContent: "center",
                            bgcolor: theme.palette.secondary.main,
                        }}
                    >
                        <Typography color={theme.palette.primary.main}>
                            Logout
                        </Typography>
                    </Button>
                ) : (
                    <Button
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            bgcolor: theme.palette.secondary.main,
                        }}
                        onClick={() => navigate('/login')}>
                        <Typography color={theme.palette.primary.main}>
                            Login
                        </Typography>
                    </Button>
                )
                }
                <a style={{display: "flex", justifyContent: "center"}}
                   href="https://apps.apple.com/us/app/bleacherfund/id1672805328?itsct=apps_box_badge&amp;itscg=30200">
                    <img alt="Download on the App Store"
                         src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1698710400"
                         style={{padding: "6%"}}
                    />
                </a>
                <a style={{display: "flex", justifyContent: "center"}}
                   href={"https://play.google.com/store/apps/details?id=com.bleacherfund.mobile&pcampaignid=web_share&pli=1&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"}>
                    <img alt='Get it on Google Play'
                         src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
                         width={130}
                    />
                </a>
            </Box>
        </Drawer>
    );
};

export default Sidebar;
