import React, {useEffect} from "react";
import Box from "@mui/material/Box";
import Container from "../../../../components/Container";
import Typography from "@mui/material/Typography";
import {Campaign} from "../../../../api";
import {Grid, useTheme} from "@mui/material";
import TitleFormatter from "../../../../utils/StringFormatters";
import useMediaQuery from "@mui/material/useMediaQuery";

interface HeroProps {
    campaign: Campaign
    teamLogoUrl: string
    logoHeight?: number
    logoWidth?: number
}

const Hero = (p: HeroProps) => {

    // const [backgroundImage, setBackroundImage] = useState("url(/football-field-background.jpg)")

    useEffect(() => {
        const jarallaxInit = async () => {
            const jarallaxElems = document.querySelectorAll('.jarallax');
            if (!jarallaxElems || (jarallaxElems && jarallaxElems.length === 0)) {
                return;
            }

            const {jarallax} = await import('jarallax');
            jarallax(jarallaxElems, {speed: 0.2});
        };

        jarallaxInit();
    });
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    return (
        <Box
            className={'jarallax'}
            data-jarallax
            data-speed="0.2"
            position={'relative'}
            minHeight={{xs: 200, sm: 200, md: 300}}
            display={'flex'}
            alignItems={'center'}
            marginTop={-13}
            paddingTop={13}
            id="agency__portfolio-item--js-scroll"
        >
            <Box
                className={'jarallax-img'}
                sx={{
                    position: 'absolute',
                    objectFit: 'cover',
                    /* support for plugin https://github.com/bfred-it/object-fit-images */
                    fontFamily: 'object-fit: cover;',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: -1,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    backgroundImage: isMd ? "url(/football-field-background.jpg)" : `url(${p.teamLogoUrl})`,
                }}
            />
            <Container position={'relative'} zIndex={2}>
                <Grid container flexDirection={"row"} justifyContent={"space-between"}>
                    <Grid
                        item
                        columns={1}
                        justifyContent={"left"}
                        alignContent={"left"}
                        color={"secondary.main"}
                        sx={{
                            textAlign: "left",
                            textShadow: "2px 2px 4px #000000",
                        }}
                    >
                        <Typography variant={"h3"}>{TitleFormatter(p.campaign.name)}</Typography>
                        <Typography variant={"h5"}>{TitleFormatter(p.campaign.tenant.name)}</Typography>
                    </Grid>

                    <img
                        src={p.teamLogoUrl}
                        height={p.logoHeight}
                        width={p.logoWidth}
                        hidden={!isMd}
                        alt={"Logo"}
                    />

                </Grid>

            </Container>
        </Box>
    )

}

export default Hero;