import {Field, Form, Formik} from "formik";
import * as Yup from 'yup';

import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import {campaignManagementApi} from "../config/RequestConfig";
import {NotifyPayloadStatusEnum} from "../api";
import React, {useState} from "react";
import {Typography} from "@mui/material";

interface NotifyCampaignDialogProps {
    message: string,
    status: NotifyPayloadStatusEnum
}

export const NotifyDialog = ({...props}) => {
    const {open, handleClose, fullScreen, campaign} = props
    const [messageStatus, updateMessageStatus] = useState(true)
    const validationSchema = Yup.object().shape({
        message: Yup.string()
            .min(2, 'Please submit a longer notification message.')
            .max(100, 'Please submit a shorter notification message.')
            .required('Required'),
    });
    const initialValues = {
        message: '',
        status: messageStatus ? NotifyPayloadStatusEnum.Public : NotifyPayloadStatusEnum.Private
    }

    async function submitNotify(data: NotifyCampaignDialogProps) {
        try {
            if (campaign.id === null) {
                throw new Error("There is an error with the current campaign")
            }
            await campaignManagementApi.notifyCampaignContacts({
                campaignId: campaign.id, notifyPayload: data
            })
        } catch (error) {
            console.log("Error submitting form data", error)
        } finally {
            handleClose()
        }
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            maxWidth={'md'}
            fullWidth={true}
            aria-labelledby="notify-dialog-title"
        >
            <DialogTitle id={"notify-dialog-title"}
                         sx={{textAlign: "center", color: "text.secondary"}}>Notify Fundraiser Contacts</DialogTitle>
            <IconButton
                sx={{position: 'absolute', right: 8, top: 8, color: 'black'}}
                aria-label={"Close notify dialog"}
                onClick={handleClose}>
                <CloseIcon/>
            </IconButton>
            <DialogContent>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={submitNotify}>
                    {({values, errors, touched, isSubmitting, handleChange, handleBlur}) => (
                        <Form id="notifyCampaignForm" autoComplete="off">
                            <TextField
                                name="message"
                                label="Message"
                                sx={{mb: 2, multilineColor: {color: "white"}}}
                                fullWidth
                                multiline
                                rows={5}
                                value={values.message}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(touched.message && errors.message)}
                                helperText={Boolean(touched.message && errors.message) ? String(errors.message) : `${values.message.length}/100`}
                                inputProps={{maxLength: 100, style: {color: "white"}}}
                            />
                            <Typography color={"secondary"}>Should the update be viewable on the fundraiser
                                page?</Typography>
                            <Typography color={"text.secondary"}>
                                <Field type="radio" name="status" value={NotifyPayloadStatusEnum.Public}/>
                                Yes
                            </Typography>
                            <Typography color={"text.secondary"}>
                                <Field type="radio" name="status" value={NotifyPayloadStatusEnum.Private}/>
                                No
                            </Typography>
                            <DialogActions>
                                <Button
                                    disabled={isSubmitting}
                                    variant="contained"
                                    type="submit"
                                    form="notifyCampaignForm"
                                    fullWidth
                                    size="large"
                                    startIcon={isSubmitting ? <CircularProgress size={"1rem"}/> : undefined}
                                    sx={{color: "primary.main", backgroundColor: "background.default"}}
                                >
                                    {isSubmitting ? <span>Sending</span> : <span>Send</span>}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    )
}
