import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {Campaign} from "../../../../api";
import {campaignApi} from "../../../../config/RequestConfig";
import {Divider, Skeleton} from "@mui/material";
import {FundraiserCard} from "../../../../components/FundraiserCard";

const FeaturedFundraisers = (): JSX.Element => {
    const [loading, setLoading] = useState<boolean>(true);
    const [featuredCampaigns, setFeaturedCampaigns] = useState<Array<Campaign>>();
    const carouselHeight = 235;

    useEffect(() => {
        setLoading(true);
        campaignApi.getFeaturedCampaigns().then((campaigns) => {
            setFeaturedCampaigns(campaigns.recentCampaigns);
        });

        setLoading(false);
    }, []);


    return (
        <Box>
            <Divider>
                <Typography
                    variant="h4"
                    align={'center'}
                    data-aos={'fade-up'}
                    gutterBottom
                    sx={{
                        fontWeight: 700,
                    }}
                >
                    Featured Fundraisers
                </Typography>
            </Divider>
            <Grid container spacing={4}>
                {loading ? (
                    <Skeleton
                        variant={"rectangular"}
                        animation={"wave"}
                        height={carouselHeight}
                        width={500}
                    />
                ) : (
                    featuredCampaigns?.map((item, i) => (
                        <FundraiserCard index={i} campaign={item}/>
                    ))
                )}
            </Grid>
        </Box>
    );
};

export default FeaturedFundraisers;
