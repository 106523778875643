/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Campaign,
  CampaignCreatePayload,
  NotifyPayload,
} from '../models/index';
import {
    CampaignFromJSON,
    CampaignToJSON,
    CampaignCreatePayloadFromJSON,
    CampaignCreatePayloadToJSON,
    NotifyPayloadFromJSON,
    NotifyPayloadToJSON,
} from '../models/index';

export interface CreateCampaignRequest {
    campaignCreatePayload: CampaignCreatePayload;
}

export interface EndCampaignRequest {
    campaignId: string;
}

export interface LaunchCampaignRequest {
    campaignId: string;
}

export interface NotifyCampaignContactsRequest {
    campaignId: string;
    notifyPayload: NotifyPayload;
}

export interface UploadFileRequest {
    campaignId: string;
    uploadType: UploadFileUploadTypeEnum;
    file: Blob;
}

/**
 * 
 */
export class CampaignManagementApi extends runtime.BaseAPI {

    /**
     */
    async createCampaignRaw(requestParameters: CreateCampaignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Campaign>> {
        if (requestParameters.campaignCreatePayload === null || requestParameters.campaignCreatePayload === undefined) {
            throw new runtime.RequiredError('campaignCreatePayload','Required parameter requestParameters.campaignCreatePayload was null or undefined when calling createCampaign.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/manage/campaign`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CampaignCreatePayloadToJSON(requestParameters.campaignCreatePayload),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CampaignFromJSON(jsonValue));
    }

    /**
     */
    async createCampaign(requestParameters: CreateCampaignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Campaign> {
        const response = await this.createCampaignRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async endCampaignRaw(requestParameters: EndCampaignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Campaign>> {
        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError('campaignId','Required parameter requestParameters.campaignId was null or undefined when calling endCampaign.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/manage/campaign/{campaignId}/end`.replace(`{${"campaignId"}}`, encodeURIComponent(String(requestParameters.campaignId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CampaignFromJSON(jsonValue));
    }

    /**
     */
    async endCampaign(requestParameters: EndCampaignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Campaign> {
        const response = await this.endCampaignRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async launchCampaignRaw(requestParameters: LaunchCampaignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Campaign>> {
        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError('campaignId','Required parameter requestParameters.campaignId was null or undefined when calling launchCampaign.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/manage/campaign/{campaignId}/launch`.replace(`{${"campaignId"}}`, encodeURIComponent(String(requestParameters.campaignId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CampaignFromJSON(jsonValue));
    }

    /**
     */
    async launchCampaign(requestParameters: LaunchCampaignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Campaign> {
        const response = await this.launchCampaignRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async notifyCampaignContactsRaw(requestParameters: NotifyCampaignContactsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError('campaignId','Required parameter requestParameters.campaignId was null or undefined when calling notifyCampaignContacts.');
        }

        if (requestParameters.notifyPayload === null || requestParameters.notifyPayload === undefined) {
            throw new runtime.RequiredError('notifyPayload','Required parameter requestParameters.notifyPayload was null or undefined when calling notifyCampaignContacts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/manage/campaign/{campaignId}/notify`.replace(`{${"campaignId"}}`, encodeURIComponent(String(requestParameters.campaignId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotifyPayloadToJSON(requestParameters.notifyPayload),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async notifyCampaignContacts(requestParameters: NotifyCampaignContactsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.notifyCampaignContactsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async uploadFileRaw(requestParameters: UploadFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError('campaignId','Required parameter requestParameters.campaignId was null or undefined when calling uploadFile.');
        }

        if (requestParameters.uploadType === null || requestParameters.uploadType === undefined) {
            throw new runtime.RequiredError('uploadType','Required parameter requestParameters.uploadType was null or undefined when calling uploadFile.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/manage/campaign/{campaignId}/{upload-type}`.replace(`{${"campaignId"}}`, encodeURIComponent(String(requestParameters.campaignId))).replace(`{${"upload-type"}}`, encodeURIComponent(String(requestParameters.uploadType))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async uploadFile(requestParameters: UploadFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.uploadFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const UploadFileUploadTypeEnum = {
    Logo: 'CAMPAIGN_LOGO',
    Gallery: 'CAMPAIGN_GALLERY'
} as const;
export type UploadFileUploadTypeEnum = typeof UploadFileUploadTypeEnum[keyof typeof UploadFileUploadTypeEnum];
