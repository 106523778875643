import {Update} from "../api";
import {Card, CardContent, Divider, Typography} from "@mui/material";
import React from "react";

interface UpdateCardProps {
    update: Update
}

export const UpdateCard = (props: UpdateCardProps) => {
    return (
        <Card
            variant="outlined"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: "100%",
                height: 100,
                justifyContent: "space-between"
            }}>

            <CardContent>
                <Divider color={"secondary.main"}>
                    <Typography variant={"h5"} color={"text.secondary"}>
                        {props.update.createdAt.toDateString()}
                    </Typography>
                </Divider>
                <Typography variant={"h6"} color={"text.secondary"} paragraph={true}>
                    {props.update.message}
                </Typography>
            </CardContent>
        </Card>
    )
}