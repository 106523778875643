import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Container,
    Grid,
    List,
    ListItem,
    ListItemText,
    Stack,
    Typography
} from "@mui/material";
import {Fundraiser} from "../api";
import {userApi} from "../config/RequestConfig";
import Button from "@mui/material/Button";
import {Main} from "../layouts";
import {USDollar} from "../utils/NumberFormatters";


export const MyCampaigns = () => {
    const navigate = useNavigate();
    const [fundraisers, setFundraisers] = useState<Fundraiser[]>([])

    useEffect(() => {
        userApi.myFundraisers()
            .then((response) => {
                setFundraisers(response.fundraisers)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])

    return (
        <Main>
            <Container sx={{padding: 2}}>
                <Grid container item padding={5} width={"100%"} bgcolor={"secondary.main"} borderRadius={5}>
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        onClick={() => navigate("/fundraiser/new")}
                    >
                        <Typography
                            color={"secondary"}
                        >
                            Create a New Fundraiser
                        </Typography>
                    </Button>
                </Grid>
                {fundraisers.map((fundraiser, index) => (
                    <Accordion key={index} sx={{marginTop: 2}}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon color={"secondary"}/>}
                            aria-controls={`panel${index}-content`}
                            id={`panel${index}-header`}
                            sx={{border: 1, borderColor: "secondary.main", borderRadius: 2.5}}
                        >
                            <Stack>
                                <Typography variant="h4" color={"secondary"}>{fundraiser.tenant.name}</Typography>
                                <Typography variant="body1"
                                            color={"secondary"}>{fundraiser.tenant.addressLineOne}</Typography>
                                {fundraiser.tenant.addressLineTwo &&
                                    <Typography variant="body1" color={"secondary"}>
                                        {fundraiser.tenant.addressLineTwo}
                                    </Typography>
                                }
                                <Typography variant="body1" color={"secondary"}>
                                    {fundraiser.tenant.city}, {fundraiser.tenant.state} {fundraiser.tenant.zipcode}
                                </Typography>
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails color={"secondary"}>
                            <List>
                                {fundraiser.campaigns.map((campaign, index) => {
                                    return (
                                        <ListItem
                                            key={index}
                                            component={Link}
                                            to={`/fundraiser/${campaign.id}`}
                                        >
                                            <ListItemText
                                                sx={{
                                                    border: 1,
                                                    padding: 2,
                                                    borderColor: "secondary.main",
                                                    borderRadius: 2.5
                                                }}
                                                primary={(
                                                    <Typography
                                                        color={"white"}
                                                        variant={"h5"}
                                                    >
                                                        [ {campaign.status} ] {campaign.name} : {USDollar.format(campaign.donationTotal)}/{USDollar.format(campaign.donationGoal)}
                                                    </Typography>)}
                                            />
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Container>
        </Main>
    )
}
