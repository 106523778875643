import React from 'react';
import Box from '@mui/material/Box';
import {useTheme} from '@mui/material/styles';

import NavItem from './components/NavItem';
import {useAuthenticator} from "@aws-amplify/ui-react";

interface Props {
    pages: {
        account: Array<PageItem>;
        company: Array<PageItem>;
    };
}

const SidebarNav = ({pages}: Props): JSX.Element => {
    const theme = useTheme();
    const {mode} = theme.palette;
    const {authStatus} = useAuthenticator(context => [context.authStatus]);
    const {
        account: accountPages,
        company: companyPages,
    } = pages;

    return (
        <Box>
            <Box width={1} paddingX={2} paddingY={1}>
                <Box
                    display={'flex'}
                    component="a"
                    href="/"
                    title="BleacherFund"
                    width={{xs: 100, md: 120}}
                >
                    <Box
                        component={'img'}
                        src={'/logo.png'}
                        height={1}
                        width={1}
                    />
                </Box>
            </Box>
            {
                authStatus === 'authenticated' ? (
                    <Box paddingX={2} paddingY={2}>
                        <Box>
                            <NavItem title={"Account"} items={accountPages}/>
                        </Box>
                    </Box>
                ) : null
            }
            <Box paddingX={2} paddingY={2}>
                <Box>
                    <NavItem title={'Company'} items={companyPages}/>
                </Box>
            </Box>
        </Box>
    );
};

export default SidebarNav;
