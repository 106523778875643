import {Typography, Paper, Container, Link, createTheme, responsiveFontSizes, ThemeProvider} from "@mui/material";
import {Main} from "../layouts";

let theme = createTheme();
theme = responsiveFontSizes(theme);

export const Privacy = () => {
    return (
        <Main>
        <Container sx={{padding: 5}}>
            <Paper elevation={3} sx={{padding: 5, bgcolor: "secondary.main"}}>
                <Typography variant={"h3"} align={"center"} paragraph={true}>BleacherFund Privacy Policy</Typography>
                <Typography variant={"body1"} paragraph={true}>
                    This Privacy Policy outlines how BleacherFund collects, uses, and protects your information when you use
                    our mobile application. We are committed to safeguarding your privacy and ensuring that your
                    personal data is handled securely and responsibly.
                </Typography>
                <Typography variant={"h5"}>Information We Collect</Typography>
                <Typography variant={"body1"} paragraph={true}>
                    Donor Information: When adults make a donation via our application, we collect and store the following information:
                    Name
                    Email address or phone number (as provided by the donor)
                    Non-Minor Users Only: Our donation functionality is intended for use by adults only. We do not knowingly store any personal information from minors under the age of 18.
                </Typography>
                <Typography variant={"h5"}>How We Use Your Information</Typography>
                <Typography variant={"body1"} paragraph={true}>
                    The information collected is used solely for the purpose of processing and acknowledging your donation. We may use your email address or phone number to:
                    Send you donation receipts or confirmations.
                    Communicate with you regarding your donation if necessary.
                </Typography>
                <Typography variant={"h5"}>Data Security</Typography>
                <Typography variant={"body1"} paragraph={true}>
                    We take your privacy seriously and have implemented reasonable security measures to protect your data from unauthorized access, disclosure, alteration, and destruction. Your personal information is stored securely, and access is restricted to authorized personnel only.
                </Typography>
                <Typography variant={"h5"}>Data Sharing and Disclosure</Typography>
                <Typography variant={"body1"} paragraph={true}>
                    We do not sell, trade, rent, or otherwise transfer your personal information to third parties. Your data is not disclosed to any external parties unless required by law or for the purpose of processing your donation.
                </Typography>
                <Typography variant={"h5"}>Data Retention</Typography>
                <Typography variant={"body1"} paragraph={true}>
                    We retain your personal information only for as long as necessary to fulfill the purposes for which it was collected or as required by law. At the end of each campaign, all donor data is expunged, ensuring that your information is not retained longer than necessary.
                </Typography>
                <Typography variant={"h5"}>Your Rights</Typography>
                <Typography variant={"body1"} paragraph={true}>
                    As a donor, you have the right to:
                    Request access to the personal information we hold about you.
                    Correct any inaccuracies in your personal information.
                    Withdraw your consent for us to process your data (subject to legal obligations).
                </Typography>
                <Typography variant={"h5"}> Contact Us </Typography>
                <Typography variant={"body1"} paragraph={true}>
                    If you have any questions, concerns, or requests related to your personal data or this Privacy Policy, please contact us at <Link href={"mailto:support@bleacherfund.com"}>support@bleacherfund.com</Link>.
                </Typography>
                <Typography variant={"h5"}>Changes to this Privacy Policy</Typography>
                <Typography variant={"body1"} paragraph={true}>
                    We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any updates will be posted on this page, and the date of the latest revision will be indicated.
                    By using our application, you consent to the terms outlined in this Privacy Policy.
                </Typography>
                <Typography>Last Updated: 10/9/2023</Typography>
            </Paper>
        </Container>
        </Main>
    )
}