import { FC, useState } from "react";
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { FieldProps, getIn } from "formik";
import { IconButton } from "@mui/material";

interface ImageUploadInputProps {
    header: string,
    limit: number,
}

interface ImageAndPreview {
    file: File,
    preview: string,
}

export const ImageUploadInput: FC<ImageUploadInputProps & FieldProps> = ({
    field: {name, onBlur},
    form: {touched, errors, setFieldValue},
    ...props
}) => {
    const [images, setImages] = useState<ImageAndPreview[]>([])
    const isMulti = props.limit > 1

    const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || e.target.files.length === 0) {
            setImages([])
            return
        }

        const fileArray = Array.from(e.target.files)
        const previewArray: ImageAndPreview[] = fileArray.map((file) => {
            return {file: file, preview: URL.createObjectURL(file)}
        })

        setFieldValue(name, fileArray)

        setImages(previewArray)
    }

    const removeFile = (index: number) => {
        const newArr = images.filter((img, i) => i !== index)
        const newFileArray: File[] = newArr.map((inp) => {
            return inp.file
        })
        setFieldValue(name, newFileArray)
        setImages(newArr)
    }

    return (
        <Grid container item justifyContent={"center"}>
            <Stack justifyContent={"center"} spacing={2} sx={{width: "100%"}}>
                <Typography variant={"h6"} alignSelf={"center"}>{props.header}</Typography>
                {images &&
                    <ImageList
                        gap={8}
                        sx={{
                            gridTemplateColumns:
                            'repeat(auto-fill, minmax(150px, 1fr))!important',
                        }}
                    >
                        {images.map((image, index) => (
                            <ImageListItem key={index}>
                                <img
                                    src={image.preview}
                                    alt={`preview of ${image.file.name}`}
                                />
                                <ImageListItemBar
                                    title={image.file.name}
                                    position="top"
                                    actionIcon={
                                        <IconButton
                                            sx={{color: 'white'}}
                                            aria-label={`remove ${image.file.name} from uploads`}
                                            onClick={() => removeFile(index)}>
                                                <CloseIcon />
                                        </IconButton>
                                    }
                                />
                                {
                                    touched[name] &&
                                    typeof errors[name] !== 'string' &&
                                    getIn(errors, `${name}[${index}]`) &&
                                    <ImageListItemBar
                                        position="below"
                                        title={String(getIn(errors, `${name}[${index}]`))}
                                        sx={{color: "#d32f2f"}} //manually added palette.error.main color
                                    />
                                }
                            </ImageListItem>
                        ))}
                    </ImageList>
                }
                <Button component="label" variant="outlined" startIcon={<CloudUploadIcon />}>
                    Upload File
                    <input
                        id={name}
                        accept="image/*"
                        hidden
                        multiple={isMulti}
                        type='file'
                        name={name}
                        onChange={onSelectFile}
                        onBlur={onBlur}
                    />
                </Button>
                {
                    touched[name] &&
                    errors[name] &&
                    typeof errors[name] === 'string' &&
                    <>{errors[name]}</>
                }
            </Stack>
        </Grid>
    )
}