/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Group } from './Group';
import {
    GroupFromJSON,
    GroupFromJSONTyped,
    GroupToJSON,
} from './Group';
import type { Tenant } from './Tenant';
import {
    TenantFromJSON,
    TenantFromJSONTyped,
    TenantToJSON,
} from './Tenant';

/**
 * 
 * @export
 * @interface Campaign
 */
export interface Campaign {
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof Campaign
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Campaign
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    teamName: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    activity: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    status: CampaignStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    creator: string;
    /**
     * 
     * @type {Tenant}
     * @memberof Campaign
     */
    tenant: Tenant;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    mission: string;
    /**
     * 
     * @type {number}
     * @memberof Campaign
     */
    donationGoal: number;
    /**
     * 
     * @type {number}
     * @memberof Campaign
     */
    donationTotal: number;
    /**
     * 
     * @type {Date}
     * @memberof Campaign
     */
    launchDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Campaign
     */
    targetEndDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Campaign
     */
    endDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    paymentLinkId?: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    paymentLink?: string;
    /**
     * 
     * @type {Array<Group>}
     * @memberof Campaign
     */
    groups?: Array<Group>;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    shortUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    youtubeLink?: string;
}


/**
 * @export
 */
export const CampaignStatusEnum = {
    Draft: 'DRAFT',
    Active: 'ACTIVE',
    Completed: 'COMPLETED'
} as const;
export type CampaignStatusEnum = typeof CampaignStatusEnum[keyof typeof CampaignStatusEnum];


/**
 * Check if a given object implements the Campaign interface.
 */
export function instanceOfCampaign(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "teamName" in value;
    isInstance = isInstance && "activity" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "creator" in value;
    isInstance = isInstance && "tenant" in value;
    isInstance = isInstance && "mission" in value;
    isInstance = isInstance && "donationGoal" in value;
    isInstance = isInstance && "donationTotal" in value;

    return isInstance;
}

export function CampaignFromJSON(json: any): Campaign {
    return CampaignFromJSONTyped(json, false);
}

export function CampaignFromJSONTyped(json: any, ignoreDiscriminator: boolean): Campaign {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'name': json['name'],
        'teamName': json['team_name'],
        'activity': json['activity'],
        'status': json['status'],
        'creator': json['creator'],
        'tenant': TenantFromJSON(json['tenant']),
        'mission': json['mission'],
        'donationGoal': json['donation_goal'],
        'donationTotal': json['donation_total'],
        'launchDate': !exists(json, 'launch_date') ? undefined : (new Date(json['launch_date'])),
        'targetEndDate': !exists(json, 'target_end_date') ? undefined : (new Date(json['target_end_date'])),
        'endDate': !exists(json, 'end_date') ? undefined : (new Date(json['end_date'])),
        'paymentLinkId': !exists(json, 'payment_link_id') ? undefined : json['payment_link_id'],
        'paymentLink': !exists(json, 'payment_link') ? undefined : json['payment_link'],
        'groups': !exists(json, 'groups') ? undefined : ((json['groups'] as Array<any>).map(GroupFromJSON)),
        'shortUrl': !exists(json, 'short_url') ? undefined : json['short_url'],
        'youtubeLink': !exists(json, 'youtube_link') ? undefined : json['youtube_link'],
    };
}

export function CampaignToJSON(value?: Campaign | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
        'name': value.name,
        'team_name': value.teamName,
        'activity': value.activity,
        'status': value.status,
        'creator': value.creator,
        'tenant': TenantToJSON(value.tenant),
        'mission': value.mission,
        'donation_goal': value.donationGoal,
        'donation_total': value.donationTotal,
        'launch_date': value.launchDate === undefined ? undefined : (value.launchDate.toISOString()),
        'target_end_date': value.targetEndDate === undefined ? undefined : (value.targetEndDate.toISOString()),
        'end_date': value.endDate === undefined ? undefined : (value.endDate.toISOString()),
        'payment_link_id': value.paymentLinkId,
        'payment_link': value.paymentLink,
        'groups': value.groups === undefined ? undefined : ((value.groups as Array<any>).map(GroupToJSON)),
        'short_url': value.shortUrl,
        'youtube_link': value.youtubeLink,
    };
}

