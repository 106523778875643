import {Grid, Skeleton} from "@mui/material";
import React, {useEffect} from "react";
import {Campaign} from "../api";
import {campaignApi} from "../config/RequestConfig";
import {FundraiserCard} from "./FundraiserCard";

export const CampaignListTable = (props: any) => {
    const [campaigns, setCampaigns] = React.useState<Campaign[]>();
    const [loading, setLoading] = React.useState<boolean>(true);
    useEffect(() => {
        campaignApi.searchCampaigns({campaignSearchPayload: {query: props.query}}).then((resp) => {
            setCampaigns(resp.campaigns);
            setLoading(false);
        })
    }, [props.query])
    let skeletonArr = new Array(3).fill('')

    return (
        <Grid
            container
            item
            spacing={1}
            bgcolor={"secondary.main"}
            padding={2}
            marginY={2}
            borderRadius={5}
        >
            {loading ?
                <>
                    {skeletonArr.map((val, index) => (
                        <Grid item key={index} xs={12} md={4}>
                            <Skeleton variant={"rectangular"} animation={"wave"} height={200} width={"100%"}/>
                        </Grid>
                    ))}
                </>
                :
                <>
                    {
                        campaigns?.length !== 0 ?
                            campaigns?.map((campaign, index) => (
                                <FundraiserCard index={index} campaign={campaign}/>
                            ))
                            : <h1>No Campaigns Found</h1>
                    }
                </>
            }
        </Grid>
    )
}