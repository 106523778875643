/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DonationEntity } from './DonationEntity';
import {
    DonationEntityFromJSON,
    DonationEntityFromJSONTyped,
    DonationEntityToJSON,
} from './DonationEntity';

/**
 * 
 * @export
 * @interface DonationResponse
 */
export interface DonationResponse {
    /**
     * 
     * @type {Array<DonationEntity>}
     * @memberof DonationResponse
     */
    donations: Array<DonationEntity>;
}

/**
 * Check if a given object implements the DonationResponse interface.
 */
export function instanceOfDonationResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "donations" in value;

    return isInstance;
}

export function DonationResponseFromJSON(json: any): DonationResponse {
    return DonationResponseFromJSONTyped(json, false);
}

export function DonationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DonationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'donations': ((json['donations'] as Array<any>).map(DonationEntityFromJSON)),
    };
}

export function DonationResponseToJSON(value?: DonationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'donations': ((value.donations as Array<any>).map(DonationEntityToJSON)),
    };
}

