import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {useTheme} from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';

import {NavItem} from './components';
import {MarketingBadges} from "../../../../components/MarketingBadges";
import {useAuthenticator} from "@aws-amplify/ui-react";
import {useNavigate} from "react-router-dom";
import {Typography} from "@mui/material";

interface Props {
    // eslint-disable-next-line @typescript-eslint/ban-types
    onSidebarOpen: () => void;
    pages: {
        account: Array<PageItem>;
        company: Array<PageItem>;
    };
    colorInvert?: boolean;
}

const Topbar = ({onSidebarOpen, pages, colorInvert = false}: Props): JSX.Element => {
    const {user, signOut} = useAuthenticator(context => [context.user]);
    const theme = useTheme();
    const {
        account: accountPages,
        company: companyPages,
    } = pages;
    const {authStatus} = useAuthenticator(context => [context.authStatus]);
    const navigate = useNavigate();

    return (
        <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            width={1}
            paddingY={2}
        >
            <Box
                display={'flex'}
                component="a"
                href="/"
                title="BleacherFund"
                width={{xs: 60, md: 80}}
            >
                <Box
                    component={'img'}
                    src={'/logo.png'}
                    height={1}
                    width={1}
                />
            </Box>

            <Box sx={{display: {xs: 'none', md: 'flex'}}} alignItems={'center'} justifyContent={'space-between'}>
                <MarketingBadges/>
                <NavItem
                    title={'Company'}
                    id={'company-pages'}
                    items={companyPages}
                    colorInvert={!colorInvert}
                />
                {authStatus === 'authenticated' ? (
                    <>
                        <NavItem
                            title={user.attributes?.name ? user.attributes?.name : "Account"}
                            id={'account-pages'}
                            items={accountPages}
                            colorInvert={!colorInvert}
                        />
                        <Button
                            onClick={() => signOut()}
                            sx={{
                                alignContent: 'center',
                                bgcolor: colorInvert ? theme.palette.primary.main : theme.palette.secondary.main,
                            }}
                        >
                            <Typography color={colorInvert ? theme.palette.text.secondary : theme.palette.primary.main}>
                                Logout
                            </Typography>
                        </Button>
                    </>
                ) : (
                    <Button
                        sx={{
                            bgcolor: colorInvert ? theme.palette.primary.main : theme.palette.secondary.main,
                        }}
                        onClick={() => navigate('/login')}>
                        <Typography color={colorInvert ? theme.palette.text.secondary : theme.palette.primary.main}>
                            Login
                        </Typography>
                    </Button>
                )
                }
            </Box>
            <Box sx={{display: {xs: 'flex', md: 'none'}}} alignItems={'center'}>
                <Button
                    onClick={() => onSidebarOpen()}
                    aria-label="Menu"
                    variant={'outlined'}
                    sx={{
                        borderRadius: 2,
                        minWidth: 'auto',
                        padding: 1,
                        borderColor: colorInvert ? theme.palette.primary.main : theme.palette.alternate.main,
                    }}
                >
                    <MenuIcon color={colorInvert ? "primary" : 'secondary'}/>
                </Button>
            </Box>
        </Box>
    );
};

export default Topbar;
