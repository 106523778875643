import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PaidIcon from "@mui/icons-material/Paid";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import React, {useEffect, useState} from "react";
import {Campaign, ManifestItem, ManifestItemUploadTypeEnum} from "../api";
import {USDollar} from "../utils/NumberFormatters";
import {campaignApi} from "../config/RequestConfig";
import TitleFormatter from "../utils/StringFormatters";

interface FundraiserCardProps {
    index: number;
    campaign: Campaign;
}

export const FundraiserCard = (props: FundraiserCardProps) => {
    const c = props.campaign;
    const i = props.index;
    const [campaignManifest, setCampaignManifest] = useState<ManifestItem[]>();
    const [campaignLogoPath, setCampaignLogoPath] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    const [daysRemaining, setDaysRemaining] = useState<number>(0);
    useEffect(() => {
        setLoading(true);
        campaignApi.getCampaignFileManifest({campaignId: c.id})
            .then((manifest) => {
                setCampaignManifest(manifest);
                setCampaignLogoPath(() => {
                    if (manifest.length == 0) return "/logo.png";

                    let logo = manifest.filter((item) =>
                        item.uploadType.valueOf() === ManifestItemUploadTypeEnum.Logo.valueOf());
                    return logo ? logo[0].path : "/logo.png";
                });

            })
            .catch((err) => console.log("Error pulling campaign manifest: ", err));
        setLoading(false)
        setDaysRemaining(() => {
            if (c.targetEndDate) {
                const now: Date = new Date();
                const diff = c.targetEndDate.getTime() - now.getTime();
                return Math.ceil(diff / (1000 * 60 * 60 * 24));
            }
            return 0
        });
    }, [c.id]);


    const handleDaysRemainingString = (): string => {
        switch (daysRemaining) {
            case 0:
                return 'Ended';
            case 1:
                return 'Ends Today'
            default:
                return `Ends in ${daysRemaining} Days`
        }
    }

    return (
        <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={i}
            data-aos={'fade-up'}
            data-aos-delay={i * 100}
            data-aos-offset={100}
            data-aos-duration={600}
        >
            <Box display={'block'} width={1} height={1}>
                <Box
                    component={Card}
                    width={1}
                    height={1}
                    display={'flex'}
                    flexDirection={'column'}
                    boxShadow={3}
                >
                    <CardMedia
                        title={c.name}
                        image={loading ? "/logo.png" : campaignLogoPath}
                        sx={{
                            position: 'relative',
                            height: {xs: 240, sm: 340, md: 280},
                            overflow: 'hidden',
                        }}
                    >
                        {c.targetEndDate !== undefined ? <Box
                            display={'flex'}
                            justifyContent={'space-between'}
                            position={'absolute'}
                            padding={2}
                            width={1}
                            bottom={0}
                        >
                            <Box
                                border={1}
                                borderColor={"background.paper"}
                                padding={1}
                                bgcolor={'background.paper'}
                                boxShadow={1}
                                borderRadius={2}
                            >
                                <Typography sx={{fontWeight: 600, color: "text.secondary"}}>
                                    {handleDaysRemainingString()}
                                </Typography>
                            </Box>
                        </Box> : null}
                    </CardMedia>
                    <CardContent>
                        <Typography
                            variant={'h5'}
                            align={'left'}
                            color={'text.secondary'}
                            sx={{fontWeight: 700}}
                        >
                            {c.name}
                        </Typography>
                        <Box display={'flex'} alignItems={'center'} marginY={2}>
                            <ApartmentIcon color={"secondary"}/>
                            <Typography variant={"h6"} color="text.secondary" marginX={2}>
                                {TitleFormatter(c.tenant.name)}
                            </Typography>
                        </Box>
                        <Box display={'flex'} alignItems={'center'} marginY={2}>
                            <PaidIcon color={"secondary"}/>
                            <Typography variant={'h6'} color="text.secondary" marginX={2}>
                                {USDollar.format(c.donationGoal)} Goal
                            </Typography>
                        </Box>
                        <CardActions sx={{justifyContent: 'flex-end'}}>
                            <Button
                                size="large"
                                color="secondary"
                                variant="contained"
                                fullWidth
                                href={`/fundraiser/${c.id}`}
                                sx={{border: 2}}
                            >
                                <Typography variant={"h5"} color={"primary"}>
                                    Donate
                                </Typography>
                            </Button>
                        </CardActions>
                    </CardContent>
                </Box>
            </Box>
        </Grid>
    )
}