export const AwsConfigAuth = {
    region: process.env.REACT_APP_AUTH_REGION,
    userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
    cookieStorage: {
        domain: process.env.REACT_APP_AUTH_COOKIE_STORAGE_DOMAIN,
        path: "/",
        expires: 365,
        sameSite: "strict",
        secure: true,
    },
    authenticationFlowType: "USER_SRP_AUTH",
    redirectUrl: process.env.REACT_APP_AUTH_CALLBACK_URL,
    oauth: {
        domain: process.env.REACT_APP_AUTH_DOMAIN,
        scope: ["email", "openid", "profile"],
        redirectSignIn: process.env.REACT_APP_AUTH_REDIRECT_SIGN_IN_URL,
        redirectSignOut: process.env.REACT_APP_AUTH_REDIRECT_SIGN_OUT_URL,
        responseType: "code",
    }
};