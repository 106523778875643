import {Card, Divider, Grid, Stack} from "@mui/material";
import {DonateAndShare} from "../../../../components/DonateAndShare";
import Typography from "@mui/material/Typography";
import React from "react";
import {Campaign} from "../../../../api";

interface MissionAndDonateProps {
    campaign: Campaign

}

const MissionAndDonate = (p: MissionAndDonateProps) => {
    return (
        <Grid
            container
            flexDirection={"row"}
        >
            <Grid
                item
                xs={12}
                sm={12}
                md={7}
                bgcolor={"secondary.main"}
                borderRadius={5}
                padding={2}
                margin={1}
                // marginY={2}
            >
                <Divider>
                    <Typography variant={"h4"} paddingY={2}>Mission</Typography>
                </Divider>
                <Stack>
                    <Card variant="outlined" sx={{flex: 1, padding: 2}}>
                        <Typography variant={"h6"} paragraph={true} color={"text.secondary"}>
                            {p.campaign.mission}
                        </Typography>
                    </Card>
                </Stack>
            </Grid>
            <Grid
                item
                xs={12}
                sm={12}
                md={4}
                bgcolor={"secondary.main"}
                borderRadius={5}
                padding={2}
                margin={1}
            >
                <DonateAndShare
                    donationTotal={p.campaign.donationTotal}
                    donationGoal={p.campaign.donationGoal}
                    paymentLink={p.campaign.paymentLink}
                    campaignId={p.campaign.id}
                />
            </Grid>
        </Grid>
    )
}

export default MissionAndDonate;