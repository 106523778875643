import Box from "@mui/material/Box"

export const MarketingBadges = () => {
    return (
        <Box display={"flex"} sx={{height: 50, width: 350, justifyContent: "flex-end"}}>
                <a style={{display: "flex", justifyContent: "center", width: 130}} href="https://apps.apple.com/us/app/bleacherfund/id1672805328?itsct=apps_box_badge&amp;itscg=30200">
                    <img alt="Download on the App Store" src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1698710400" style={{padding:"6%"}}/>
                </a>
                <a style={{ display: "flex", justifyContent: "center" }} href={"https://play.google.com/store/apps/details?id=com.bleacherfund.mobile&pcampaignid=web_share&pli=1&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"}>
                    <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'width={130}/>
                </a>
        </Box>
    )
}