import {Field, FieldArray, Form, Formik, getIn} from "formik";
import {useNavigate} from "react-router-dom";

import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import RemoveIcon from '@mui/icons-material/Remove';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import {CircularProgress, FormControl, FormHelperText} from "@mui/material";
import {ImageUploadInput} from "../components/ImageUploadInput";
import {campaignManagementApi, userApi} from "../config/RequestConfig";
import {useEffect, useState} from "react";
import {GroupCreatePayload, Tenant} from "../api";
import {CampaignCreationSchema} from "../components/CampaignCreationValidator";
import {Main} from "../layouts";

interface FormValues {
    tenantId: string,
    name: string,
    teamName: string,
    activity: string,
    mission: string,
    donationGoal: number,
    targetEndDate: Date,
    code: string,
    youtubeLink: string,
    groups: GroupCreatePayload[],
    teamLogo: Blob[],
    photoGallery: Blob[],
}

async function submitForm(formData: FormValues, setError: (field: string, message: string | undefined) => void) {
    const {teamLogo, photoGallery, ...campaignData} = formData
    const createPayload = {...campaignData, targetEndDate: new Date(campaignData.targetEndDate)}
    try {
        const {id} = await campaignManagementApi.createCampaign({campaignCreatePayload: createPayload})
        const imgResponse = await Promise.all(photoGallery.map(async (photo) => {
            return campaignManagementApi.uploadFile({campaignId: id, uploadType: 'CAMPAIGN_GALLERY', file: photo})
        }))
        imgResponse.push(
            await campaignManagementApi.uploadFile({campaignId: id, uploadType: 'CAMPAIGN_LOGO', file: teamLogo[0]})
        )
        return {id: id, imgResponse: imgResponse}
    } catch (error: any) {
        console.error("Error submitting form data", error)
        if (error.response && error.response.status === 409) {
            setError("code", "Mobile code already in use. Please select another.")
        } else {
            alert("An error occurred while creating your campaign.")
        }
    }
}

export const CampaignCreation = () => {
    const navigate = useNavigate();
    const [organizations, setOrganizations] = useState<Tenant[]>([])
    const initialValues: FormValues = {
        tenantId: '',
        name: '',
        teamName: '',
        activity: '',
        mission: '',
        donationGoal: 0,
        targetEndDate: new Date(),
        code: '',
        youtubeLink: '',
        groups: [{name: ''}],
        teamLogo: [],
        photoGallery: [],
    }

    useEffect(() => {
        userApi.myTenantsFromJWT()
            .then((response) => {
                setOrganizations(response.tenants)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])

    return (
        <Main>
            <Container sx={{padding: 1, bgcolor: "secondary.main"}}>
                <Typography
                    variant="h4"
                    align="center"
                    gutterBottom
                    color={"text.alternate"}
                >
                    Draft a New Fundraiser
                </Typography>
                <Paper variant="outlined" sx={{
                    padding: 2, bgcolor: "secondary.main",
                    "& .MuiFormLabel-colorPrimary": {
                        color: "text.alternate"
                    },
                }}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={CampaignCreationSchema}
                        onSubmit={async (values, {resetForm, setFieldError}) => {
                            const response = await submitForm(values, setFieldError)
                            if (response) {
                                resetForm()
                                navigate(`fundraiser/${response.id}`)
                            }
                        }}>
                        {({values, errors, touched, isSubmitting, handleChange, handleBlur}) => (
                            <Form autoComplete="off">
                                <Grid container spacing={2} justifyContent={"center"}>

                                    {/* Text Uploads */}
                                    <Grid container item md={6}>
                                        <Stack spacing={2} width={"100%"}>
                                            <TextField
                                                name="name"
                                                label="Fundraiser Name"
                                                value={values.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={Boolean(touched.name && errors.name)}
                                                helperText={
                                                    touched.name &&
                                                    errors.name &&
                                                    String(errors.name)
                                                }
                                            />
                                            <FormControl error={Boolean(touched.tenantId && errors.tenantId)}>
                                                <InputLabel id="organization-select-label">Organization</InputLabel>
                                                <Select
                                                    id="organization-select-label"
                                                    name="tenantId"
                                                    label="Organization"
                                                    value={values.tenantId}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                >
                                                    {organizations?.map((org, index) => (
                                                        <MenuItem key={index} value={org.id}
                                                                  color={"text.secondary"}>{org.name}</MenuItem>
                                                    ))}
                                                </Select>
                                                <FormHelperText>{touched.tenantId && errors.tenantId && String(errors.tenantId)}</FormHelperText>
                                            </FormControl>
                                            <TextField
                                                name="teamName"
                                                label="Team Name"
                                                value={values.teamName}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={Boolean(touched.teamName && errors.teamName)}
                                                helperText={
                                                    touched.teamName &&
                                                    errors.teamName &&
                                                    String(errors.teamName)
                                                }
                                            />
                                            <TextField
                                                name="activity"
                                                label="Activity"
                                                value={values.activity}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={Boolean(touched.activity && errors.activity)}
                                                helperText={
                                                    touched.activity &&
                                                    errors.activity &&
                                                    String(errors.activity)
                                                }
                                            />
                                            <TextField
                                                name="mission"
                                                label="Mission"
                                                multiline
                                                rows={5}
                                                value={values.mission}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={Boolean(touched.mission && errors.mission)}
                                                helperText={
                                                    touched.mission &&
                                                    errors.mission &&
                                                    String(errors.mission)
                                                }
                                            />
                                            <TextField
                                                name="donationGoal"
                                                type="number"
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                                                }}
                                                label="Donation Goal"
                                                value={values.donationGoal}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={Boolean(touched.donationGoal && errors.donationGoal)}
                                                helperText={
                                                    touched.donationGoal &&
                                                    errors.donationGoal &&
                                                    String(errors.donationGoal)
                                                }
                                            />
                                            <TextField
                                                name="targetEndDate"
                                                type="date"
                                                label="Target End Date"
                                                value={values.targetEndDate}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={Boolean(touched.targetEndDate && errors.targetEndDate)}
                                                helperText={
                                                    touched.targetEndDate &&
                                                    errors.targetEndDate &&
                                                    String(errors.targetEndDate)
                                                }
                                            />
                                            <TextField
                                                name="code"
                                                label="Mobile App Code"
                                                value={values.code}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={Boolean(touched.code && errors.code)}
                                                helperText={
                                                    touched.code &&
                                                    errors.code &&
                                                    String(errors.code)
                                                }
                                            />
                                            <TextField
                                                name="youtubeLink"
                                                label="YouTube Link"
                                                value={values.youtubeLink}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {/* Groups */}
                                            <FieldArray
                                                name="groups"
                                                render={({push, remove}) => (
                                                    <Stack>
                                                        {values.groups.map((group, index) => (
                                                            <Grid key={index} container item
                                                                  justifyContent={"space-between"} mb={2}>
                                                                <Grid item flexGrow={1}>
                                                                    <TextField
                                                                        fullWidth
                                                                        name={`groups.${index}.name`}
                                                                        label="Group"
                                                                        value={values.groups[index].name}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        error={Boolean(
                                                                            getIn(errors, `groups[${index}]`) &&
                                                                            getIn(touched, `groups[${index}]`)
                                                                        )
                                                                        }
                                                                        helperText={
                                                                            getIn(errors, `groups[${index}]`) &&
                                                                            getIn(touched, `groups[${index}]`) &&
                                                                            String(getIn(errors, `groups[${index}].name`))
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <IconButton aria-label={"Remove Group"}
                                                                            onClick={() => remove(index)}>
                                                                    <RemoveIcon/>
                                                                </IconButton>
                                                            </Grid>
                                                        ))}
                                                        {values.groups.length < 5 ?
                                                            <Button onClick={() => push({name: ""})}>Add
                                                                Group</Button> : undefined}
                                                    </Stack>
                                                )}
                                            />
                                        </Stack>
                                    </Grid>

                                    {/* Image Uploads */}
                                    <Grid container item md={6}>
                                        {/* Team Logo */}
                                        <Field
                                            name="teamLogo"
                                            header={"Team Logo"}
                                            limit={1}
                                            component={ImageUploadInput}
                                            value={values.teamLogo}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />

                                        {/* Photo Gallery */}
                                        <Field
                                            name="photoGallery"
                                            header={"Photo Gallery"}
                                            limit={5}
                                            component={ImageUploadInput}
                                            value={values.photoGallery}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </Grid>

                                    <Grid container item sm={12} md={6}>
                                        <Button
                                            disabled={isSubmitting}
                                            variant="contained"
                                            type="submit"
                                            fullWidth
                                            size="large"
                                            startIcon={isSubmitting ? <CircularProgress size={"1rem"}/> : undefined}
                                        >
                                            {isSubmitting ? <span>Creating</span> : <span>Create</span>}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>

                </Paper>
            </Container>
        </Main>
    )
}