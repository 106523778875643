/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Tenant,
  TenantCreatePayload,
} from '../models/index';
import {
    TenantFromJSON,
    TenantToJSON,
    TenantCreatePayloadFromJSON,
    TenantCreatePayloadToJSON,
} from '../models/index';

export interface CreateTenantRequest {
    tenantCreatePayload: TenantCreatePayload;
}

export interface GetTenantByIdRequest {
    id: string;
}

/**
 * 
 */
export class TenantManagementApi extends runtime.BaseAPI {

    /**
     */
    async createTenantRaw(requestParameters: CreateTenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Tenant>> {
        if (requestParameters.tenantCreatePayload === null || requestParameters.tenantCreatePayload === undefined) {
            throw new runtime.RequiredError('tenantCreatePayload','Required parameter requestParameters.tenantCreatePayload was null or undefined when calling createTenant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/tenant`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TenantCreatePayloadToJSON(requestParameters.tenantCreatePayload),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TenantFromJSON(jsonValue));
    }

    /**
     */
    async createTenant(requestParameters: CreateTenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Tenant> {
        const response = await this.createTenantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTenantByIdRaw(requestParameters: GetTenantByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Tenant>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTenantById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/tenant/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TenantFromJSON(jsonValue));
    }

    /**
     */
    async getTenantById(requestParameters: GetTenantByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Tenant> {
        const response = await this.getTenantByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
