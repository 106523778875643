import {useState} from "react";

import {Button, Card, Grid, Typography, useTheme} from "@mui/material";

import {Campaign} from "../api"

import {NotifyDialog} from "../components/NotifyCampaignDialog";
import {CampaignStatusDialog} from "../components/CampaignStatusDialog"

import useMediaQuery from "@mui/material/useMediaQuery"

export interface OrganizerControlsProps {
    campaign: Campaign,
}

export const OrganizerControls = (props: OrganizerControlsProps) => {
    const {campaign} = props;
    const [open, setOpen] = useState(false);
    const [openCampaignStatus, setOpenCampaignStatus] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleOpenCampaignStatus = () => setOpenCampaignStatus(true);
    const handleCloseCampaignStatus = () => setOpenCampaignStatus(false);
    const getButton = () => {
        switch (campaign.status) {
            case "ACTIVE":
                return <Button size={"large"} variant={"contained"} onClick={handleOpenCampaignStatus}>End
                    Campaign</Button>
            case "DRAFT":
                return <Button size={"large"} variant={"contained"} onClick={handleOpenCampaignStatus}>Launch
                    Campaign</Button>
        }
    }

    return (
        <Grid container item maxWidth={{sm: "100%", md: "93%"}}>
            <Card variant="outlined" sx={{width: "100%", padding: 2, marginY: 2, backgroundColor: "secondary.main"}}>
                <Grid container item justifyContent={"center"}>
                    <Typography color={"primary"} variant="h4" textAlign={"center"} gutterBottom>Coach
                        Tools</Typography>
                </Grid>
                <Grid container item justifyContent={"space-around"} spacing={1}>
                    <Grid item>
                        <Button size={"large"} variant={"contained"} onClick={handleOpen}>Notify Team</Button>
                    </Grid>
                    <Grid item>
                        {getButton()}
                    </Grid>
                </Grid>
            </Card>
            <NotifyDialog
                open={open}
                handleClose={handleClose}
                fullScreen={fullScreen}
                campaign={campaign}
            />
            <CampaignStatusDialog
                open={openCampaignStatus}
                handleClose={handleCloseCampaignStatus}
                fullScreen={fullScreen}
                campaign={campaign}
            />
        </Grid>
    )
}
