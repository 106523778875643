import React from 'react';
import {useTheme} from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

const mock = [
    {
        name: 'Dan Lampe',
        title: 'Co-Founder & CEO',
        avatar: 'dan.png',
    },
    {
        name: 'John Lampe',
        title: 'Co-Founder & CPO',
        avatar: 'john.jpg',
    },
    {
        name: 'Ryan Haney',
        title: 'Co-Founder & CTO',
        avatar: 'ryan.jpg',
    },
];

const Team = (): JSX.Element => {
    const theme = useTheme();

    return (
        <Box>
            <Box marginBottom={4}>
                <Typography
                    variant="h4"
                    align={'center'}
                    gutterBottom
                    sx={{
                        fontWeight: 700,
                        marginTop: theme.spacing(1),
                    }}
                    color={'text.secondary'}
                >
                    Small team. Big hearts.
                </Typography>
            </Box>
            <Grid container spacing={2}>
                {mock.map((item, i) => (
                    <Grid item xs={12} md={4} key={i}>
                        <Box
                            width={1}
                            height={1}
                            component={Card}
                            boxShadow={0}
                            variant={'outlined'}
                            bgcolor={'secondary.main'}
                        >
                            <CardContent sx={{padding: 3}}>
                                <ListItem component="div" disableGutters sx={{padding: 0}}>
                                    <ListItemAvatar sx={{marginRight: 3}}>
                                        <Avatar
                                            src={item.avatar}
                                            variant={'rounded'}
                                            sx={{width: 100, height: 100, borderRadius: 2}}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        sx={{margin: 0}}
                                        primary={item.name}
                                        secondary={item.title}
                                        primaryTypographyProps={{variant: 'h6', fontWeight: 700}}
                                        secondaryTypographyProps={{variant: 'subtitle1', color: 'text.primary'}}
                                    />
                                </ListItem>
                            </CardContent>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default Team;
