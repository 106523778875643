import {Box, Grid, List, ListItem, Tab, Tabs, Typography} from "@mui/material";

import React, {SyntheticEvent, useEffect, useState} from "react";
import {DonationEntity} from "../../../../api";
import {donationApi} from "../../../../config/RequestConfig";
import {DonationCard} from "../../../../components/DonationCard";

interface CampaignDonationsProps {
    campaignId: string;
}

const Donations = (p: CampaignDonationsProps) => {

    const [latestDonations, setLatestDonations] = useState<DonationEntity[]>()
    const [topDonations, setTopDonations] = useState<DonationEntity[]>([])
    const [value, setValue] = useState(0)
    const tabHeight = 50;
    // const panelHeight = p.height ? p.height - tabHeight : "250px";

    useEffect(() => {

        donationApi.getLatestDonations({campaignId: p.campaignId})
            .then((donations) => {
                setLatestDonations(donations.donations);
            })
            .catch((err) => console.log("Error pulling latest donations: ", err));
        donationApi.getCampaignLeaderboard({campaignId: p.campaignId})
            .then((donations) => {
                setTopDonations(donations);
            })
            .catch((err) => console.log("Error pulling top donations: ", err));
    }, [p.campaignId]);

    const handleTabChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    }

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`
        };
    }

    interface TabPanelProps {
        children?: React.ReactNode;
        index: number;
        value: number;
    }

    function CustomTabPanel(props: TabPanelProps) {
        const {children, value, index, ...other} = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    return (
        <Grid
            item
            xs={12}
            sm={12}
            md={6}
            bgcolor={"secondary.main"}
            borderRadius={5}
            padding={2}
            margin={1}
        >
            <Box>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs
                        value={value}
                        onChange={handleTabChange}
                        aria-label="donations"
                        variant={"fullWidth"}
                        sx={{height: tabHeight, paddingX: 1}}
                    >
                        <Tab label={(
                            <Typography variant={"h5"} color={"text.primary"}>Top</Typography>)} {...a11yProps(0)}
                             color={"primary"}/>
                        <Tab label={(
                            <Typography variant={"h5"} color={"text.primary"}>Recent</Typography>)} {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <List sx={{width: "100%", overflow: "auto"}}>
                        {(topDonations === undefined || topDonations.length === 0) ? (
                            <Typography fontSize={"1.65rem"} align="center">No Donations Yet</Typography>
                        ) : (
                            <>
                                {topDonations.slice(0, 3).map((donation, index) => {
                                    return (
                                        <ListItem
                                            key={index}
                                            sx={{
                                                paddingX: 1,
                                                paddingY: 0.5
                                            }}
                                        >
                                            <DonationCard
                                                variant={"top"}
                                                donationSum={donation.amount}
                                                title={`${donation.firstName} ${donation.lastInitial}.`}
                                                rank={index + 1}
                                            />
                                        </ListItem>
                                    )
                                })}
                            </>
                        )}
                    </List>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <List sx={{width: "100%", overflow: "auto"}}>
                        {(latestDonations === undefined || latestDonations.length === 0) ? (
                            <Typography
                                fontSize={"1.65rem"}
                                align="center">
                                No Donations Yet
                            </Typography>
                        ) : (
                            <>
                                {latestDonations?.slice(0, 3).map((donation, index) => {
                                    return (
                                        <ListItem
                                            key={index}
                                            sx={{
                                                paddingX: 0,
                                                paddingY: 0.5
                                            }}
                                        >
                                            <DonationCard
                                                title={`${donation.firstName} ${donation.lastInitial}.`}
                                                donationSum={donation.amount}
                                                variant={"latest"}
                                            />
                                        </ListItem>
                                    )
                                })}
                            </>
                        )}
                    </List>
                </CustomTabPanel>
            </Box>
        </Grid>
    )
}

export default Donations;