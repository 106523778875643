import {Main} from "../../layouts";
import Container from "../../components/Container";
import {Divider, Grid, Skeleton} from "@mui/material";
import React, {useEffect, useState} from "react";
import Hero from "./components/Hero";
import {Campaign, ManifestItem, ManifestItemUploadTypeEnum} from "../../api";
import {campaignApi, userApi} from "../../config/RequestConfig";
import {useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import TitleFormatter from "../../utils/StringFormatters";
import GroupLeaderboard from "./components/GroupLeaderboard";
import Donations from "./components/Donations";
import MissionAndDonate from "./components/MissionAndDonate";
import {CampaignUpdateList} from "../../components/CampaignUpdateList";
import {OrganizerControls} from "../../components/OrganizerControls";

const Fundraiser = () => {
    const params = useParams();
    const campaignId = params.id ? params.id : null;
    const [loading, setLoading] = useState(true);
    const [campaign, setCampaign] = useState<Campaign>();
    const [campaignManifest, setCampaignManifest] = useState<ManifestItem[]>();
    const [campaignLogo, setCampaignLogo] = useState<ManifestItem>();
    const [userIsOrganizer, setUserIsOrganizer] = useState(false);


    useEffect(() => {
        setLoading(true);
        if (campaignId === null) {
            return;
        }
        campaignApi.getCampaign({id: campaignId}).then((campaign) => {
            setCampaign(campaign);
        }).catch((err) => console.log("Error pulling campaign: ", err));

        campaignApi.getCampaignFileManifest({campaignId: campaignId})
            .then((manifest) => {
                setCampaignManifest(manifest);
            })
            .catch((err) => console.log("Error pulling campaign manifest: ", err));
        setLoading(false);
    }, []);

    useEffect(() => {
        if (campaignManifest) {
            setCampaignLogo(getManifestItemUrl(ManifestItemUploadTypeEnum.Logo)[0]);
            // setCampaignGallery(getManifestItemUrl(ManifestItemUploadTypeEnum.Gallery));
            setLoading(false);
        }
    }, [campaignManifest])

    useEffect(() => {
        if (campaign) {
            userApi.myCampaigns().then((response) => {
                const campIds = response.campaigns.map((camp) => camp.id)
                setUserIsOrganizer(campaign ? campIds.includes(campaign.id) : false);
            }).catch((error) => {
                console.log("Error pulling user campaigns: ", error)
            })
        }
    }, [campaign])

    function getManifestItemUrl(manifestItemType: ManifestItemUploadTypeEnum): ManifestItem[] {
        return campaignManifest?.filter((item) => item.uploadType.valueOf() === manifestItemType.valueOf()) ?? [];
    }

    return (
        <Main>
            {campaign === undefined || loading ?
                <Skeleton variant={"rectangular"} height={800} width={"100%"}/>
                : (
                    <>
                        <Hero
                            campaign={campaign}
                            teamLogoUrl={campaignLogo ? campaignLogo.path : "logo.png"}
                            logoHeight={150}
                            logoWidth={150}
                        />
                        <Container>
                            {/* Organizer Controls */}
                            {(userIsOrganizer && campaign) &&
                                <OrganizerControls campaign={campaign}/>
                            }
                            <Grid
                                item
                                justifyContent={"left"}
                                alignContent={"left"}
                                color={"secondary.main"}
                                flex={1}
                                marginBottom={5}
                                sx={{
                                    textAlign: "center",
                                    textShadow: "2px 2px 4px #000000",
                                }}
                            >

                                <Typography variant={"h4"}>
                                    {TitleFormatter(campaign.teamName)} Fundraiser
                                </Typography>

                            </Grid>

                            <MissionAndDonate campaign={campaign}/>
                            <Grid
                                container
                                flexDirection={"row"}
                            >
                                <Donations campaignId={campaign.id}/>
                                <GroupLeaderboard campaign={campaign}/>
                            </Grid>
                            <Grid
                                item
                                bgcolor={"secondary.main"}
                                borderRadius={5}
                                padding={2}
                                margin={1}
                                maxWidth={{sm: "100%", md: "93%"}}
                            >
                                <Divider>
                                    <Typography
                                        variant={"h4"}
                                        paddingY={2}
                                    >
                                        Latest Fundraiser Updates
                                    </Typography>
                                </Divider>
                                <CampaignUpdateList campaign={campaign}/>
                            </Grid>
                        </Container>
                    </>
                )}
        </Main>
    )
}

export default Fundraiser;