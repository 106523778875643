/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IndividualLeaderboardItem
 */
export interface IndividualLeaderboardItem {
    /**
     * 
     * @type {number}
     * @memberof IndividualLeaderboardItem
     */
    position: number;
    /**
     * 
     * @type {string}
     * @memberof IndividualLeaderboardItem
     */
    group: string;
    /**
     * 
     * @type {number}
     * @memberof IndividualLeaderboardItem
     */
    donationSum: number;
}

/**
 * Check if a given object implements the IndividualLeaderboardItem interface.
 */
export function instanceOfIndividualLeaderboardItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "position" in value;
    isInstance = isInstance && "group" in value;
    isInstance = isInstance && "donationSum" in value;

    return isInstance;
}

export function IndividualLeaderboardItemFromJSON(json: any): IndividualLeaderboardItem {
    return IndividualLeaderboardItemFromJSONTyped(json, false);
}

export function IndividualLeaderboardItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): IndividualLeaderboardItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'position': json['position'],
        'group': json['group'],
        'donationSum': json['donation_sum'],
    };
}

export function IndividualLeaderboardItemToJSON(value?: IndividualLeaderboardItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'position': value.position,
        'group': value.group,
        'donation_sum': value.donationSum,
    };
}

