import '@aws-amplify/ui-react/styles.css';
import {Authenticator, ThemeProvider, useAuthenticator} from '@aws-amplify/ui-react';
import React from "react";
import Box from "@mui/material/Box";

import {Main} from "../layouts";
import Home from "../views/Home";

export const SignIn = () => {
    const {authStatus} = useAuthenticator(context => [context.authStatus]);
    return (
        <>
            {
                authStatus !== 'authenticated' ?
                    <Main>
                        <Box padding={10}>
                            <ThemeProvider>
                                <Authenticator
                                    socialProviders={['facebook', 'google']}
                                    hideSignUp
                                />
                            </ThemeProvider>
                        </Box>
                    </Main>
                    : <Home/>
            }
        </>
    )
};
