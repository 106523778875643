/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DonationEntity,
  DonationResponse,
  IndividualLeaderboardItem,
} from '../models/index';
import {
    DonationEntityFromJSON,
    DonationEntityToJSON,
    DonationResponseFromJSON,
    DonationResponseToJSON,
    IndividualLeaderboardItemFromJSON,
    IndividualLeaderboardItemToJSON,
} from '../models/index';

export interface GetCampaignLeaderboardRequest {
    campaignId: string;
}

export interface GetGroupLeaderboardRequest {
    campaignId: string;
}

export interface GetLatestDonationsRequest {
    campaignId: string;
}

/**
 * 
 */
export class DonationsApi extends runtime.BaseAPI {

    /**
     */
    async getCampaignLeaderboardRaw(requestParameters: GetCampaignLeaderboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DonationEntity>>> {
        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError('campaignId','Required parameter requestParameters.campaignId was null or undefined when calling getCampaignLeaderboard.');
        }

        const queryParameters: any = {};

        if (requestParameters.campaignId !== undefined) {
            queryParameters['campaign_id'] = requestParameters.campaignId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/leaderboard/campaign`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DonationEntityFromJSON));
    }

    /**
     */
    async getCampaignLeaderboard(requestParameters: GetCampaignLeaderboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DonationEntity>> {
        const response = await this.getCampaignLeaderboardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getGroupLeaderboardRaw(requestParameters: GetGroupLeaderboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<IndividualLeaderboardItem>>> {
        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError('campaignId','Required parameter requestParameters.campaignId was null or undefined when calling getGroupLeaderboard.');
        }

        const queryParameters: any = {};

        if (requestParameters.campaignId !== undefined) {
            queryParameters['campaign_id'] = requestParameters.campaignId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/leaderboard/group`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(IndividualLeaderboardItemFromJSON));
    }

    /**
     */
    async getGroupLeaderboard(requestParameters: GetGroupLeaderboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<IndividualLeaderboardItem>> {
        const response = await this.getGroupLeaderboardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getLatestDonationsRaw(requestParameters: GetLatestDonationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DonationResponse>> {
        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError('campaignId','Required parameter requestParameters.campaignId was null or undefined when calling getLatestDonations.');
        }

        const queryParameters: any = {};

        if (requestParameters.campaignId !== undefined) {
            queryParameters['campaign_id'] = requestParameters.campaignId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/donations/latest`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DonationResponseFromJSON(jsonValue));
    }

    /**
     */
    async getLatestDonations(requestParameters: GetLatestDonationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DonationResponse> {
        const response = await this.getLatestDonationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
