/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ManifestItem
 */
export interface ManifestItem {
    /**
     * 
     * @type {string}
     * @memberof ManifestItem
     */
    uploadType: ManifestItemUploadTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ManifestItem
     */
    lastModified: string;
    /**
     * 
     * @type {string}
     * @memberof ManifestItem
     */
    path: string;
    /**
     * 
     * @type {number}
     * @memberof ManifestItem
     */
    size: number;
}


/**
 * @export
 */
export const ManifestItemUploadTypeEnum = {
    Logo: 'CAMPAIGN_LOGO',
    Gallery: 'CAMPAIGN_GALLERY'
} as const;
export type ManifestItemUploadTypeEnum = typeof ManifestItemUploadTypeEnum[keyof typeof ManifestItemUploadTypeEnum];


/**
 * Check if a given object implements the ManifestItem interface.
 */
export function instanceOfManifestItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uploadType" in value;
    isInstance = isInstance && "lastModified" in value;
    isInstance = isInstance && "path" in value;
    isInstance = isInstance && "size" in value;

    return isInstance;
}

export function ManifestItemFromJSON(json: any): ManifestItem {
    return ManifestItemFromJSONTyped(json, false);
}

export function ManifestItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManifestItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uploadType': json['upload_type'],
        'lastModified': json['last_modified'],
        'path': json['path'],
        'size': json['size'],
    };
}

export function ManifestItemToJSON(value?: ManifestItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'upload_type': value.uploadType,
        'last_modified': value.lastModified,
        'path': value.path,
        'size': value.size,
    };
}

