import React, {useState} from 'react';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';

import Container from '../../components/Container';

import {Footer, Sidebar, Topbar} from './components';

import pages from '../navigation';

interface Props {
    children: React.ReactNode;
    colorInvert?: boolean;
    bgcolor?: string;
}

const Main = ({
                  children,
                  colorInvert = false,
                  bgcolor = 'transparent',
              }: Props): JSX.Element => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [openSidebar, setOpenSidebar] = useState(false);

    const handleSidebarOpen = (): void => {
        setOpenSidebar(true);
    };

    const handleSidebarClose = (): void => {
        setOpenSidebar(false);
    };

    const open = isMd ? false : openSidebar;

    return (
        <Box>
            <AppBar
                position={'sticky'}
                sx={{top: 0, backgroundColor: theme.palette.background.paper}}
            >
                <Container paddingY={1}>
                    <Topbar
                        onSidebarOpen={handleSidebarOpen}
                        pages={pages}
                        colorInvert={false}
                    />
                </Container>
            </AppBar>
            <Sidebar
                onClose={handleSidebarClose}
                open={open}
                variant="temporary"
                pages={pages}
            />
            <main>
                {children}
                <Divider/>
            </main>
            <Container paddingY={4}>
                <Footer/>
            </Container>
        </Box>
    );
};

export default Main;
