/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {alpha, useTheme} from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';

import Container from 'components/Container';
import {useNavigate} from "react-router-dom";

const Hero = (): JSX.Element => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [searchCriteria, setSearchCriteria] = React.useState('');
    return (
        <Box
            minHeight={300}
            height={'auto'}
            position={'relative'}
            sx={{
                backgroundColor: theme.palette.alternate.main,
                background:
                    'url(football-field-background.jpg) no-repeat center',
                backgroundSize: 'cover',
                marginTop: -13,
                paddingTop: 13,
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    width: 1,
                    height: 1,
                    background: alpha(theme.palette.primary.dark, 0.3),
                    zIndex: 1,
                }}
            />
            <Container position={'relative'} zIndex={2}>
                <Box>
                    <Box marginBottom={4} data-aos="fade-up">
                        <Typography
                            variant="h3"
                            gutterBottom
                            sx={{
                                fontWeight: 900,
                                color: 'common.white',
                            }}
                        >
                            Fundraise Better
                        </Typography>
                        <Typography
                            variant="h6"
                            component="p"
                            color="text.primary"
                            sx={{
                                fontWeight: 400,
                                color: 'common.white',
                            }}
                        >
                            BleacherFund is the ultimate app designed to revolutionize school sports fundraising,
                            empowering
                            teams to reach their goals and achieve victory both on and off the field. Say goodbye to
                            traditional
                            fundraising challenges and hello to a streamlined, efficient, and engaging experience!
                        </Typography>
                    </Box>
                    <Box
                        padding={{xs: 3, sm: 6}}
                        width={1}
                        component={Card}
                        boxShadow={1}
                        data-aos="fade-up"
                        bgcolor={'alternate.main'}
                    >
                        <form noValidate autoComplete="off">
                            <Box display="flex" flexDirection={{xs: 'column', md: 'row'}}>
                                <Box
                                    width={1}
                                    marginRight={{xs: 0, md: 2}}
                                    marginBottom={{xs: 2, md: 0}}
                                >
                                    <TextField
                                        sx={{
                                            height: 54,
                                        }}
                                        variant="outlined"
                                        color="primary"
                                        size="medium"
                                        fullWidth
                                        onChange={(e) => setSearchCriteria(e.target.value)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Box
                                                        component={'svg'}
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                        width={24}
                                                        height={24}
                                                        color={'primary.main'}
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth={2}
                                                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                                        />
                                                    </Box>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <Button
                                        sx={{height: 54, whiteSpace: 'nowrap'}}
                                        variant="contained"
                                        type={"submit"}
                                        color="primary"
                                        size="medium"
                                        fullWidth
                                        onClick={() => navigate({pathname: '/search', search: `?q=${searchCriteria}`})}
                                    >
                                        Search
                                    </Button>
                                </Box>
                            </Box>
                        </form>
                        <Box marginY={4} marginX={{xs: -3, sm: -6}}>
                            <Divider/>
                        </Box>
                        <Box>
                            <Typography
                                component="p"
                                variant="body2"
                                align="left"
                                color={theme.palette.primary.main}
                            >
                                By clicking on "Search" you agree to our{' '}
                                <Box
                                    component="a"
                                    href="/privacy"
                                    color={theme.palette.primary.main}
                                    fontWeight={'700'}
                                >
                                    Privacy Policy
                                </Box>
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default Hero;
